.page-section {
  // normal lists
  > ul:not(.theme-list):not(.agenda-items):not(.v-search-results):not(.arrangements):not(.page-share-wrapper) {
    @include grid-padding;
    @include ul-styling;
  }

  > ol {
    @include content-section-block;
    @include ol-styling;
  }
}

ul {
  // links lists

  &.content-list-links {
    li {
      padding-bottom: 5px;
    }

    li::before {
      color: $color-main-black;
      content: "#{$icon-full-arrow-right}";
      font-family: icons;
      font-size: 13px;
      position: absolute;
      left: 3px;
    }
  }

  // download lists
  &.content-list-downloads {
    li::before {
      color: $color-main-black;
      content: "#{$icon-download}";
      font-family: icons;
      font-size: 15px;
      position: absolute;
      left: 0;
    }
  }
}

.activity-downloads {
  @include typography-paragraph;
  @include rich-text;

  span.file {
    clear: left;
    display: block;
    margin-bottom: 5px;

    a {
      padding-left: 25px;
      display: inline-block;
    }
  }

  span.file:before {
    content: "#{$icon-download}";
    color: $color-main-black;
    font-family: icons;
    font-size: 15px;
    vertical-align: middle;
    padding: 3px 5px 0 5px;
    position: absolute;
  }
}

.checkbox-list {
  li {
    input[type="checkbox"] + h4 {
      margin-top: -25px;
    }
  }
}
