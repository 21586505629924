
.page-representation {

  .page-representation__header,
  header {
    @include clearfix;

    position: relative;
  }

  .page-representation__header {
    display: block;
    background-color: $color-main-grey;
  }

  @include below($breakpoint-mobile) {
    .e-text {
      display: none;
    }
  }

  .ribbon-mobile {
    display: none;
  }

  .ribbon {
    display: block;
  }

  @include below($breakpoint-mobile-overview-blocks) {
    .ribbon-mobile {
      display: block;
    }

    .ribbon {
      display: none;
    }
  }

  &.video-block {
    @include below($breakpoint-mobile-overview-blocks) {
      margin-bottom: 10px;
      margin-top: 10px;
      width: 100%;
    }

    @include above($breakpoint-mobile-overview-blocks) {
    }
  }
}
