/**
 * @license
 * MyFonts Webfont Build ID 2700764, 2013-12-03T11:38:10-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Corbel Bold Italic by Ascender
 * URL: http://www.myfonts.com/fonts/ascender/corbel/bold-italic/
 *
 * Webfont: Corbel by Ascender
 * URL: http://www.myfonts.com/fonts/ascender/corbel/regular/
 *
 * Webfont: Corbel Bold by Ascender
 * URL: http://www.myfonts.com/fonts/ascender/corbel/bold/
 *
 * Webfont: Corbel Italic by Ascender
 * URL: http://www.myfonts.com/fonts/ascender/corbel/italic/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2700764
 * Licensed pageviews: 250,000
 * Webfonts copyright: &#x00A9; 2007 Microsoft Corporation. All Rights Reserved.
 *
 * © 2013 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */

@font-face {font-family: 'Corbel';font-weight: bold;font-style: italic;src: url('../fonts/corbel/2935DC_0_0.eot');src: url('../fonts/corbel/2935DC_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/corbel/2935DC_0_0.woff') format('woff'),url('../fonts/corbel/2935DC_0_0.ttf') format('truetype'),url('../fonts/corbel/2935DC_0_0.svg#wf') format('svg');}


@font-face {font-family: 'Corbel';font-weight: normal;font-style: normal;src: url('../fonts/corbel/2935DC_1_0.eot');src: url('../fonts/corbel/2935DC_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/corbel/2935DC_1_0.woff') format('woff'),url('../fonts/corbel/2935DC_1_0.ttf') format('truetype'),url('../fonts/corbel/2935DC_1_0.svg#wf') format('svg');}


@font-face {font-family: 'Corbel';font-weight: bold;font-style: normal;src: url('../fonts/corbel/2935DC_2_0.eot');src: url('../fonts/corbel/2935DC_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/corbel/2935DC_2_0.woff') format('woff'),url('../fonts/corbel/2935DC_2_0.ttf') format('truetype'),url('../fonts/corbel/2935DC_2_0.svg#wf') format('svg');}


@font-face {font-family: 'Corbel';font-weight: normal;font-style: italic;src: url('../fonts/corbel/2935DC_3_0.eot');src: url('../fonts/corbel/2935DC_3_0.eot?#iefix') format('embedded-opentype'),url('../fonts/corbel/2935DC_3_0.woff') format('woff'),url('../fonts/corbel/2935DC_3_0.ttf') format('truetype'),url('../fonts/corbel/2935DC_3_0.svg#wf') format('svg');}

/*
 * OTHER FONTS: Klavika and icons
 */

$api: false !default;

@if(not $api){

  @font-face {
    font-family: 'Klavika';
    src: url('../fonts/klavika-bold-webfont.eot');
    src: local('☺'), url('../fonts/klavika-bold-webfont.woff') format('woff'), url('../fonts/klavika-bold-webfont.svg') format('svg');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Klavika';
    src: url('../fonts/klavika-regular-webfont.eot');
    src: local('☺'), url('../fonts/klavika-regular-webfont.woff') format('woff'), url('../fonts/klavika-regular-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  /**
   * @license
   * MyFonts Webfont Build ID 3187250, 2016-03-16T04:25:04-0400
   *
   * The fonts listed in this notice are subject to the End User License
   * Agreement(s) entered into by the website owner. All other parties are
   * explicitly restricted from using the Licensed Webfonts(s).
   *
   * You may obtain a valid license at the URLs below.
   *
   * Webfont: Campton-Book by Rene Bieder
   * URL: http://www.myfonts.com/fonts/rene-bieder/campton/book/
   *
   * Webfont: Campton-Medium by Rene Bieder
   * URL: http://www.myfonts.com/fonts/rene-bieder/campton/medium/
   *
   *
   * License: http://www.myfonts.com/viewlicense?type=web&buildid=3187250
   * Licensed pageviews: 10,000
   * Webfonts copyright: Copyright &#x00A9; 2014 by Ren&#x00E9; Bieder. All rights reserved.
   *
   * © 2016 MyFonts Inc
  */


  /* @import must be at top of file, otherwise CSS will not work */
  @font-face {font-family: 'Campton Book';src: url('/static/fonts/campton-webfonts/30E1AE_0_0.eot');src: url('/static/fonts/campton-webfonts/30E1AE_0_0.eot?#iefix') format('embedded-opentype'),url('/static/fonts/campton-webfonts/30E1AE_0_0.woff2') format('woff2'),url('/static/fonts/campton-webfonts/30E1AE_0_0.woff') format('woff'),url('/static/fonts/campton-webfonts/30E1AE_0_0.ttf') format('truetype');}
  @font-face {font-family: 'Campton Medium';src: url('/static/fonts/campton-webfonts/30E1AE_1_0.eot');src: url('/static/fonts/campton-webfonts/30E1AE_1_0.eot?#iefix') format('embedded-opentype'),url('/static/fonts/campton-webfonts/30E1AE_1_0.woff2') format('woff2'),url('/static/fonts/campton-webfonts/30E1AE_1_0.woff') format('woff'),url('/static/fonts/campton-webfonts/30E1AE_1_0.ttf') format('truetype');}

  //@font-face {
  //  font-family: 'Campton Book';
  //  src: url('../fonts/campton/book.otf');
  //  font-weight: normal;
  //  font-style: normal;
  //}
  //
  //@font-face {
  //  font-family: 'Campton Medium';
  //  src: url('../fonts/campton/medium.otf');
  //  font-weight: normal;
  //  font-style: normal;
  //}
  //
  //@font-face {
  //  font-family: 'Campton SemiBold';
  //  src: url('../fonts/campton/semibold.otf');
  //  font-weight: normal;
  //  font-style: normal;
  //}

}

@font-face {
  font-family: 'icons';

  src: url('/static/fonts/icomoon.ttf?nemo') format('truetype');

  font-weight: normal;
  font-style: normal;
}


$klavika: Klavika, sans-serif;
$corbel: Corbel, sans-serif;
$corbel-regular: Corbel W01 Regular, sans-serif;
$corbel-bold: Corbel W01 Bold, sans-serif;
$corbel-italic: Corbel W01 Italic, sans-serif;
$corbel-bold-italic: Corbel W01 Bold Italic, sans-serif;

$font-campton-book: Campton Book, sans-serif;
$font-campton-medium: Campton Medium, sans-serif;
$font-campton-semibold: Campton SemiBold, sans-serif;


$default-font-size: 19px;

// Redesign

$font-base  : "Helvetica Neue", "Helvetica", "Arial", sans-serif;
