.c-menu-overlay {
  background-color: rgba(black, 0.64);
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $layer-menu-overlay;

  .e-title {
    @include typography-science-museum;
    color: $color-main-green;
    position: fixed;
    bottom: 30px;
    left: 30px;
  }

  &.is-active, .c-main-menu.is-active & {
    height: 100%;
  }
}