/**
 * Buttons
 */

.c-menu-button {

  $size: $button-size;
  @include typography-button;
  @include text-icon-button-size-variation($size, $location: 'before');
  @include base-icon($icon-data-hamburger, $fg-color: $color-main-white, $bg-color: none, $size: $size,
  $location: 'before') {
    margin-right: 0;
  }
  padding-right: 35px;
  background-color: $color-main-black;
  color: $color-main-white;
  cursor: pointer;
  display: inline-block;
  vertical-align: bottom;

  &:active {
    outline: none;
  }

  &:hover, & .on-hover,
  &:active, &.on-click {
    @include icon-color-variation($fg-color: $color-main-black, $bg-color: none, $location: 'before');
    background-color: $color-main-white;
    color: $color-main-black;
  }

  &.menu-is-open {
    @include base-icon($icon-data-close, $fg-color: $color-main-black, $bg-color: $color-main-green, $size: $size,
    $location: 'before') {
      margin-right: 0;
    }
    background-color: $color-main-green;
    color: $color-main-black;

    &:hover, &.on-hover,
    &:active, &.on-click {
      @include icon-color-variation($fg-color: $color-main-black, $bg-color: $color-main-white, $location: 'before');
      background-color: $color-main-white;
      color: $color-main-black;
    }
  }

  @include below($breakpoint-mobile) {
    font-size: 0;
    padding: 0;

    &::before {
      margin: 0;
    }
  }
}

.button-block {
  margin-bottom: 50px; // big padding, because button block should be at end of section
  padding: 0 ($grid-tile + $gutter);

  @include below($breakpoint-portrait) {
    padding: 0; // ($grid-tile + $gutter)/2;
    text-align: center;
  }

  .button {
    @include text-icon-button;
    &:hover {
      text-decoration: none;

      a {
        text-decoration: none;
      }
    }
  }
}

.reserve-button {
  @include text-icon-button;

  a {
    color: $color-main-black !important;
    text-decoration: none !important;
  }

  &:hover {
    a {
      color: $color-main-white !important;
    }
  }
}

.c-side-navigation .e-container .e-visible-icons .c-menu-button {
  padding-left: calc(50% + -4px);
  transform: translateX(-46%);

  width: auto;
  position: relative;
  right: -50%;
  top: 0px;


  &:before {
    padding-left: 0 ;
    margin-left: 0 ;
    position: absolute;
    left: 10px;
  }

  @include below($breakpoint-mobile) {
    width: 53px;
 
    &:before {
      left: 3px;
    }
  }
  
}


.c-main-menu.is-active {
  .c-side-navigation .e-container .e-visible-icons .c-menu-button {
    right: -38%; 
  }
}