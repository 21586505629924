.content-page {
  .page-title-label {
    // No glitches with element placement
    font-size: 0;
  }

  .ribbon {
    font-size: 14px;
    line-height: 32px;
  }

  .ribbon-container {
    // Don't make this one influence the height calculations
    background: transparent;
    position: absolute;
    top: $logosize / 1% * 1vw - .5vw;

    @include below($breakpoint-mobile) {
      top: $logominsize;
    }

    @include above($breakpoint-maxlogowidth) {
      top: $logomaxwidth;
    }
  }

  .page-header-visual {
    .ribbon-container {
      top: -92px;

      @include below($breakpoint-mobile) {
        top: -42px;
      }

      @include above($breakpoint-maxlogowidth) {
        top: -92px;
      }
    }
  }

  .ribbon-rotater {
    float: left;
    overflow: hidden;
    left: 0;
    top: 0;
    @include transform(rotate(270deg) translate(-100%, -100%));
    @include transform-origin(top left);

    .ribbon {
      position: static;
    }
  }

  @include below($breakpoint-mobile) {
    .page-sections {
      margin-top: 240px;
    }

    .ribbon  {
      font-size: 12px;
      height: 20px;
      line-height: 22px;
    }
  }

  .page-section {
    .custom-divider {
      clear: both;
    }
  }
}

.content-page--newsletter {
  .form form .checkbox-list.richtext-labels li > label {
    width: auto;
  }
}



.preview-mode {
  display: none !important;
}