$worksheet-block-height: 440px;
$intro-height: 218px;
$block-footer-height: 57px;
$block-margin: 36px;

@mixin block-footer {
  background-color: $color-main-white;
  border-top: 3px solid $color-main-grey;
  padding: 15px 20px;
  height: auto;
  clear: both;

  p {
    @include typography-small;
    display: block;
    padding-right: 5px;
  }

  a {
    @include typography-small;
  }
}

.page-representation.worksheet, .page-representation.activity {
  @include below($breakpoint-mobile-overview-blocks) {
    header {
      display: none;
    }

    > a {
      display: inline-block;
      bottom: -3px;
    }
  }

  .activity__label {
    @include coloured-label-small;
    margin: 5px 0 10px 0;
  }

  .content-list-downloads {
    position: relative;

    li {
      padding-left: 20px;
    }
  }
}

.page-representation.worksheet {
  @include rich-text;
  background-color: $color-main-white;

  @include above($breakpoint-mobile-overview-blocks) {
    height: $worksheet-block-height;

    .c-grid-intro {
      min-height: $intro-height;
    }
  }

  footer {
    @include block-footer;
  }
}

.section-intermezzo + .button-block {
  background-color: $color-main-grey;
  padding-bottom: 35px;
}

.course-material-detail {
  .page-title {
    padding-bottom: $margin-small;
  }
}

.course-material {
  label {
    @include coloured-label-small;
    margin: 5px 0 10px 0;
  }
}

.page-representation.activity {
  // in case they do want to display the footer
  //> a {
  //  height: auto;
  //}

  footer {
    display: none;
  }
}

.activity-detail-page {
  .page-section-first {
    > p:not(.page-section__intro) {
      @include grid-padding($mode: "margin");
      border-top: 2px solid $color-main-grey;
      border-bottom: 2px solid $color-main-grey;
      padding: $margin-small 0;
    }
  }
}

.activity-details {
  @include content-section-block;
  @include typography-default;
  margin-bottom: $margin;

  dt {
    width: 150px;
    float: left;
    @include typography-default-bold;
  }

  dd {
    padding-bottom: 5px;
  }
}

.activity-downloads {
  .file {
    @include content-section-block;
  }
}