// List of menu navigation items
.c-nav-list {
  > .nav-item + .nav-item {
  }

  ul {
    display: none;

    &.is-open {
      display: block;
    }
  }
  @include below($breakpoint-portrait) {
    margin-bottom: 50px;
  }
}

$base-padding: 40px;
$icon-padding: 30px;

// Navigation menu item or navigation sub menu item
.c-search-widget a {
  @include typography-header-4($line-height:70px 70px);
  color: $color-main-white;
  height: 70px;
  padding-left: $icon-padding;
  padding-right: $base-padding;
  position: relative;
  $icon-tuple: "" $icon-search 70;
  $icon-size: 30px;
  @include below($breakpoint-portrait) {
    bottom: 20px;
  }
  @include base-icon-raw($icon-search, $bg-color:transparent, $fg-color:$color-secondary-grey) {
    font-size: $icon-size * 0.6;
    line-height: 23px;
    @include above($breakpoint-portrait) {
      line-height: 30px;
    }
    position: absolute;
    left: 0;
    top: -3px;
  }
  @include hover {
    color: $color-main-green;
    &:after {
      color: $color-main-green;
    }
  }

}

.nav-item {

  a {
    display: block;

    &:hover, &.on-hover {
      color: $color-main-green;
    }
  }

  > a {
    @include typography-header-4($line-height:70px 70px);
    color: $color-main-white;
    height: 70px;
    padding-left: $icon-padding + $base-padding;
    padding-right: $base-padding;
    position: relative;
    @include below($breakpoint-portrait) {
      @include typography-header-4($line-height:60px 60px);
      height: 60px;
    }
  }

  // Navigation sub menu item
  .nav-item {
    > a {
      @include typography-default($line-height:38px 38px);
      height: 38px;

      &::before {
        display: none;
      }

      &:hover, &.on-hover {
        color: $color-main-green;
      }
    }
  }

  // Navigation menu item with sub menu
  &.has-sub-menu {
    > a {
      &::before {
        color: $color-secondary-grey;
        content: '+';
        left: $base-padding;
        position: absolute;
        text-align: center;
        width: 20px;
      }

      &:hover, &.on-hover {
        &::before {
          color: $color-main-green;
        }
      }
    }
  }

  // Expanded navigation menu item with sub menu
  &.has-sub-menu.is-expanded {
    > a {
      &::before {
        content: '-';
      }
    }
  }
}

// anchor navigation

.anchor-navigation {
  @include content-section-block;
  padding-bottom: 20px;

  li {
    display: inline-block;
  }

  .button-anchor {
    @include text-icon-button;
    @include themed-support;
    margin: 0 2px 5px 0;

    &:hover {
      background-color: $color-main-black !important;
    }
  }
}