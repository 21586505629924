.section-intermezzo {
  body & {
    background-color: $color-main-grey;
  }
  margin-bottom: $margin;

  .section-inner {
    @include grid-padding;
    padding-top: 50px;
    padding-bottom: 15px;
  }

  //see styles from overview-page blocks
  .page-representation {
    footer {
      .link-block {
        display: none;
      }
    }

    @include below(1376px) {

    }
  }
}

// two gray areas after one another

.section-intermezzo + .v-grey {
  margin-top: -45px;
}

.section-intermezzo + .page-section-related {
  margin-top: -55px;
}
