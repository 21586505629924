.page-section-facilities {
  padding-top: $margin-small;

  .arrangements {
    @include content-section-block;
    @include typography-default;
    padding-top: $margin;
    padding-bottom: 50px;

    li {
      color: $color-main-black;
      display: inline-block;
      width: 49%;
      max-width: 250px;

      &::before {
        margin-right: 10px;
        margin-bottom: 20px;
      }

      > h4 {
        @include typography-default-bold;
      }
    }
  }
}

.arrangements {
  .cabaret {
    @include facility-icon($icon-cabaret);
  }

  .buffet {
    @include facility-icon($icon-buffet);
  }

  .reception {
    @include facility-icon($icon-reception);
  }

  .theater {
    @include facility-icon($icon-theater);
  }

  .diner {
    @include facility-icon($icon-diner);
  }

  .u-shape {
    @include facility-icon($icon-u-shape);
  }

  .party {
    @include facility-icon($icon-party);
  }
}

.page-representation {
  .arrangements {
    margin-top: 10px;

    li {
      &::before {
        font-size: 25px;
      }

      > span {
        display: none;
      }
    }
  }
}