.page-section-pushcontent {
  @include content-section-block;
  @include max-width;
  margin-bottom: $margin;

  @include below($breakpoint-medium) {
    max-width: 100%;
    padding-right: 20px;
  }

  @include below($breakpoint-small) { 
    padding-right: 60px;
  }


  .section-inner {
    @include clearfix;
    background-color: $color-main-green;
  }

  h4 {
    @include typography-header-4;
    padding-bottom: 20px;
  }

  figure {
    font-size: 0;
    //max-width: 100%;

    img {
      width: 100%;
    }

    .e-desktop {
      display: none;
    }

    @include above(800px) {
      display: inline-block;
      float: left;
      max-width: 260px;

      .e-mobile {
        display: none;
      }

      .e-desktop {
        display: block;
      }
    }
  }

  .content-info {
    @include typography-default;
    padding: 20px;
    overflow-wrap: break-word;

    p:not(:last-child) {
      margin-bottom: 20px;
    }
    
    .content-info__title {
      hyphens: none;
      font-size: 26px;
      line-height: 30px;
      padding-bottom: 20px;
    }

    a {
      &::before {
        content: "#{$icon-full-arrow-right}";
        font-family: icons;
        font-size: 11px;
        padding-right: 10px;
      }

      span {
        text-decoration: underline;
      }
    }

    @include above(800px) {
      @include calc(width, "100% - 290px"); // 100% - imagewidth
      float: left;
    }
  }
}
