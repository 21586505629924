
.page-footer {
  position: relative;
  z-index: $layer-content;
  text-align: center;

  .page-footer-columns {
    display: inline-block;
    font-size: 0;
    box-sizing: border-box;
    margin: 0 auto;
    @include max-width();

    .page-footer-column {
      //border: 1px solid pink;
      width: 25%;
      padding: 0;
      margin: 70px 0;
      padding-left: 0;//$grid-tile-landscape/2;
      @include below($breakpoint-landscape) {
        //padding-left: $grid-tile-portrait/2;
      }
      @include below($breakpoint-portrait) {
        //width: 240px;
        //padding-left: $grid-tile-mobile/2;
        margin: 25px 0;
      }

      display: inline-block;
      vertical-align: top;
      text-align: left;

      .e-footer-list-item {
        @include base-icon($icon-data-triangle-right,$button-size - 10,$bg-color: transparent,$fg-color:$color-main-green,$location:'before');
        background-color: $color-main-black;
        
        > a {
          margin-left: 5px;
          @include typography-default;
          color: $color-main-white;

          &:hover {
            color: $color-main-green;
          }
        }

        &::before {
          width: auto;
          height: auto;
          line-height: 24px;
        }
      }

      &.last {
        width: 50%;
        padding: 0 75px 0 30px;
        padding-left: $grid-tile-landscape/2;
        @include below($breakpoint-portrait) {
          width: 50%;
          padding: 0;
          margin: 0px auto;
          display: block;
          text-align: left;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }

        &::after {
          left: auto;
          right: 0;
        }

        > p {
          display: inline-block;
          @include typography-default;
          color: $color-main-white;
        }

        .follow-wrapper {
          margin: 10px 0;
          font-size: 0;

          > li {
            display: inline-block;
            border: none;
            margin: 0 1px 0 0;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            font-weight: normal;
            vertical-align: top;
            cursor: pointer;
          }
          .facebook {
            > a {
              @include base-icon($icon-data-facebook,$button-size - 10,$bg-color: $color-main-green,$fg-color:$color-main-black,$location:'before');

              &::before {
                padding: 0 15px 0 0;
              }

              &:hover {

                &::before {
                  background-color: $color-main-white;
                }
              }

              > span {
                display: block;
                overflow: hidden;
                position: absolute;
                width: 1px;
                height: 1px;
              }
            }
          }

          .tiktok {
            > a {
              @include base-icon($icon-data-tiktok,$button-size - 10,$bg-color:$color-main-green,$fg-color:$color-main-black,$location:'before');

              &:hover {
                &::before {
                  background-color: $color-main-white;
                }
              }

              > span {
                display: block;
                overflow: hidden;
                position: absolute;
                width: 1px;
                height: 1px;
              }
            }
          }

          .instagram {
            > a {
              @include base-icon($icon-data-instagram,$button-size - 10,$bg-color:$color-main-green,$fg-color:$color-main-black,$location:'before');
              vertical-align: bottom;

              &:hover {
                &::before {
                  background-color: $color-main-white;
                }
              }

              > span {
                display: block;
                overflow: hidden;
                position: absolute;
                width: 1px;
                height: 1px;

                &::before {
                  vertical-align: bottom;
                }
              }
            }
          }

          .newsletter {
            margin: 0 0 0 20px;
            background-color: $color-main-green ;

            &:hover {
              background-color: $color-main-white;
            }

            > a {
              @include base-icon($icon-data-mail,$button-size - 10,$bg-color:transparent,$fg-color:$color-main-black,$location:'before');
              vertical-align: bottom !important;

              > span {
                @include typography-default;
                display: inline-block;
                vertical-align: bottom;
                padding: 10px 20px 6px 0;
                color: $color-main-black;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .page-footer-sponsors {
    width: 100%;
    height: 100px;
    background-color: white;
    
    .page-footer-sponsors-wrapper {
      max-width: 990px;
      margin: 0 auto;
      padding: 25px 25px 0 0;
      text-align: right;
      font-size: 0;

      img {
        width: 220px;
        display: inline-block;
      }
      
      > span {
        vertical-align: bottom;
        margin-right: 10px;
        @include typography-default-bold;
      }
    }
  }

  @include below($breakpoint-mobile) {
    text-align: left;

    .page-footer-columns {
      height: 170px;
      padding: 20px 10px 0 10px;

      .page-footer-column {

        &:first-of-type {
          display: none;
        }

        &.middle {
          display: none;
        }

        &.last {
          min-width: 0;
          width: 100%;
          padding: 0px;
          text-align: center;

          h4 {
            display: block;
          }
        }
      }
    }

    .page-footer-sponsors {
      .page-footer-sponsors-wrapper {
        padding: 20px;
        text-align: left;
      }
    }
  }
}