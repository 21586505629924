
.skip-to-main {
  height: 0;
  overflow: visible;
  text-align: center;
  width: 100%;

  .c-text-button {
    display: inline-block;
    left: -200vw;
    position: absolute;
    top: 20px;
    float: left;

    &:focus,
    &:active {
      float: none;
      left: auto;
      position: relative;
      z-index: 9999;
    }
  }
}
