.c-side-navigation {
  $base-padding: 40px;
  $width: $main-menu-side-width;
  @include transition-property(transform);
  @include transition-duration(400ms);
  @include transition-timing-function(ease-out);
  background-color: $color-main-black;
  height: 100%;
  position: fixed;
  right: -$width;
  top: 0;
  width: $width;
  z-index: $layer-menu-overlay + 1;

  @include below($breakpoint-portrait) { 
    z-index: 710;
  }

  &:focus-within,
  &:focus {
    outline: none;
    border: none;
  }

  > .e-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .e-scrollable-container {
    height: 100%;
    padding: 20px 0;
    overflow: auto;
    width: 100%;
    .e-top-row {
      padding-left: $base-padding;
      padding-right: 70px;
      margin-bottom: 20px;
    }
    .e-language-row, .e-buy-row {
      display: inline-block;
      line-height: 40px;
    }
    .e-buy-row {
      float: right;
      .e-link {
        @include typography-default;
        @include base-icon($icon-data-triangle-right,$button-size - 10,$bg-color: transparent,$fg-color:$color-main-green,$location:'before');
        color: $color-main-green;

        &:hover, &.on-hover {
          color: $color-main-white;
          &:before {
            color: $color-main-white;
          }
        }
        &:before {
          width: 20px;
        }
      }
    }

    .e-search-row {
        padding-left: $base-padding;
    }
    @include above($breakpoint-portrait) {
      .e-nav-row {
        margin-bottom: 120px;
      }
    }

    @include below($breakpoint-portrait) {
      height: 90%;
      .e-search-row {
        background-color: $color-main-black;
        bottom: 0;
        height: 55px;
        position: absolute;
        width: 100%;
        z-index: 1;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          height: 160px;
          background: linear-gradient(to bottom, transparent 0, #000000 110px, #000000 100%);
          width: 100%;
          z-index: -1;
          pointer-events: none;
        }
      }
    }
  }

  .e-visible-icons {
    position: absolute;
    right: $width;
    width: 128px; // exactly fit the menu item, so individual pages cant add any buttons

    ul {
      @include clearfix;
      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    li {
      &:first-child { // Assuming this is the tickets button container
        .c-text-button {
          width: 150px;
          padding: 0;
          text-align: center;
        }
      }
    }
  }

  &.is-open, .c-main-menu.is-active & {
    @include transform(translateX(-$width));
  }

  @include below($breakpoint-landscape) {
  }

  @include below($breakpoint-mobile) {
    @include transition(none);
    right: -100%;
    width: 100%;
    z-index: $layer-header + 1;  // hide logo

    .e-visible-icons {
      position: fixed;
      right: 0;
      width: 50px;
      z-index: 10;
    }

    &.is-open, .c-main-menu.is-active & {
      @include transform(translateX(-100%));
    }
  }
}


.c-main-menu {
  
  .c-side-navigation .e-scrollable-container {
      display: none;
  }
}

.c-main-menu.is-active  {
  .c-side-navigation .e-scrollable-container {
      display: block;
  }
}

.c-menu-button:focus {
  outline: 1px solid #005fcc;
}
