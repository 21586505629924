// Redesign colors
$color-main-green: #00E63C;
$color-main-black: #000000;
$color-main-red: #FF3C00;
$color-main-pink: #F03CA5;
$color-main-yellow: #FFE600;
$color-main-blue: #0096FA;
$color-main-white: #FFFFFF;
$color-main-grey: #E6E6E6;

// Secondary colors
$color-secondary-green: #00C333;
$color-lightest-grey: #F4F4F4;
$color-secondary-grey: #707070;
$color-tertiary-grey: #666666;
$color-grey-99: #767676;
$color-and-yet-another-grey: #cfcfcf;
$color-secondary-black: #333333;

// Social media colors
$facebook-blue: #3B5998;
$twitter-blue: #00ACEE;
$youtube-red: #CD332D;

$main-colors: (
    "green" $color-main-green,
    "black" $color-main-black,
    "red" $color-main-red,
    "pink" $color-main-pink,
    "yellow" $color-main-yellow,
    "blue" $color-main-blue,
    "grey" $color-main-grey
);

div.c-color-block {
  display: inline-block;
  height: 126px;
  width: 126px;

  @each $color in $main-colors {
    $name: nth($color, 1);
    $value: nth($color, 2);

    &.v-#{$name} {
      background-color: $value;
    }
  }
}

@if $with-base-css {
  @include selection() {
    background-color: $color-main-green;
    color: $color-main-black;
  }
}
