$ezmark-clean-up: true;
$ezmark-spacing: 40px;
$ezmark-layout: vertical;
//@import "sprites/ezmark/*.png";

@mixin ezmark-sprite($name, $offset-x:0, $offset-y:0) {
  /* Todo: something with this. */
}

// ez-mark
.ez-checkbox, .ez-radio {
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
  > input {
    cursor: pointer;
  }
}

//.ez-hide {
//  opacity: 0;
//}

// checkbox
.ez-checkbox {
  @include ezmark-sprite(checkbox);
}

.ez-checked {
  @include ezmark-sprite(checkbox-checked);
}

.ez-checkbox.focus {
  @include ezmark-sprite(checkbox-focus);
}

.ez-checked.focus {
  @include ezmark-sprite(checkbox-checked-focus);
}

//radio
.ez-radio {
  @include ezmark-sprite(radio, $offset-x: 5px, $offset-y: 5px);
}

.ez-selected {
  @include ezmark-sprite(radio-checked, $offset-x: 5px, $offset-y: 5px);
}

.ez-radio.focus {
  @include ezmark-sprite(radio-focus, $offset-x: 5px, $offset-y: 5px);
}

.ez-selected.focus {
  @include ezmark-sprite(radio-checked-focus, $offset-x: 5px, $offset-y: 5px);
}