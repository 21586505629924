body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

@mixin rich-text($link-color:$color-main-black, $link-underline:true) {
  a:not(.contest-video):not(.button) {
    @include typography-paragraph;
    color: $link-color;

    @if $link-underline {
      text-decoration: underline;
    }

    &:hover {
      text-decoration: none;
    }

    @include selection(true) {
      color: $color-main-white;
    }
  }

  strong, b {
    font-weight: bold;
  }

  em, i {
    font-style: italic;
  }
}

@mixin ul-styling($accent-color:$color-main-black, $link-color:$color-main-black, $link-underline:true) {
  @include typography-default;
  @include rich-text;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 7px;

  li {
    position: relative;
    display: block;
    padding-left: 25px;
    padding-bottom: 5px;
  }

  &:not(.content-list-downloads):not(.content-list-links):not(.arrangements):not(.page-share-wrapper) > li::before {
    content: "■";
    padding-right: 10px;
    color: $color-main-black;
    position: relative;
    left: 0;
  }
}

@mixin ol-styling($link-color:$color-main-black, $link-underline:true) {
  @include typography-default;
  @include rich-text($link-underline:$link-underline);

  counter-reset: foo;
  display: table;

  li {
    list-style: none;
    counter-increment: foo;
    display: table-row;

    &::before {
      content: counter(foo) ".";
      display: table-cell;
      text-align: right;
      padding-right: 11px;
    }
  }
}


.c-rich-text {
  @include rich-text;

  ol {
    @include ol-styling;
  }

  ul {
    @include ul-styling;
  }

  h3 {
    @include typography-header-3;
  }

  h4 {
    @include typography-header-5;
  }
}
