.c-background-video {
  &.v-home-slide {
    z-index: 1;
  }

  background: no-repeat #000;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: -100;
}