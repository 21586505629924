.quote-left, .quote-right {
  @include grid-padding;
  @include typography-intro;
  clear: both;
  position: relative;
  margin: 40px 0;

  p {
    @include typography-intro;;
  }

  &::before {
    position: absolute;
    left: 0;
    content: " ";
    border-left: 40px solid $color-main-green;
    height: 100%;

    @include below(530px) {
      border-left: 10px solid $color-main-green;
    }
  }

  .quote-source {
    @include typography-default;
    color: $color-tertiary-grey;
    display: block;
    margin-top: 15px;
  }
}