//
// RESTYLING
//

// for main color: @include themed-main;
// for support color: @include themed-support;

$themes: pink, blue, red, yellow, grey;
$theme-default-support: $color-main-green;

// pink theme
$theme-pink-background-color: $color-main-pink;
$theme-pink-support: $color-main-pink;

// blue theme
$theme-blue-background-color: $color-main-blue;
$theme-blue-support: $color-main-blue;

// red theme
$theme-red-background-color: $color-main-red;
$theme-red-support: $color-main-red;

// yellow theme
$theme-yellow-background-color: $color-main-yellow;
$theme-yellow-support: $color-main-yellow;

// grey theme
$theme-grey-background-color: $color-main-grey;
$theme-grey-support: $color-main-green;

//
// OLD THEMES REFACTORED
//

$theme-yellow-background-color: $color-main-yellow;
$theme-yellow-background: $color-main-yellow;
$theme-yellow-background-fade: $color-main-yellow;
$theme-yellow-background-fade-fixed: $color-main-yellow;
$theme-yellow-background-reversed: $color-main-yellow;
$theme-yellow-support: $color-main-yellow;
$theme-yellow-support-complement: $color-main-yellow;
$theme-yellow-signal: $color-main-yellow;

$theme-purple-background-color: $color-main-pink;
$theme-purple-background: $color-main-pink;
$theme-purple-background-fade: $color-main-pink;
$theme-purple-background-fade-fixed: $color-main-pink;
$theme-purple-background-reversed: $color-main-pink;
$theme-purple-support: $color-main-pink;
$theme-purple-support-complement: $color-main-pink;
$theme-purple-signal: $color-main-pink;

$theme-blue-background-color: $color-main-blue;
$theme-blue-background: $color-main-blue;
$theme-blue-background-fade: $color-main-blue;
$theme-blue-background-fade-fixed: $color-main-blue;
$theme-blue-background-reversed: $color-main-blue;
$theme-blue-support: $color-main-blue;
$theme-blue-support-complement: $color-main-blue;
$theme-blue-signal: $color-main-blue;

$theme-orange-background-color: $color-main-red;
$theme-orange-background: $color-main-red;
$theme-orange-background-fade: $color-main-red;
$theme-orange-background-fade-fixed: $color-main-red;
$theme-orange-background-reversed: $color-main-red;
$theme-orange-support: $color-main-red;
$theme-orange-support-complement: $color-main-red;
$theme-orange-signal: $color-main-red;

$theme-green-background-color: $color-main-grey;
$theme-green-background: $color-main-grey;
$theme-green-background-fade: $color-main-grey;
$theme-green-background-fade-fixed: $color-main-grey;
$theme-green-background-reversed: $color-main-grey;
$theme-green-support: $color-main-green;
$theme-green-support-complement: $color-main-green;
$theme-green-signal: $color-main-green;

$theme-pink-background-color: $color-main-pink;
$theme-pink-background: $color-main-pink;
$theme-pink-background-fade: $color-main-pink;
$theme-pink-background-fade-fixed: $color-main-pink;
$theme-pink-background-reversed: $color-main-pink;
$theme-pink-support: $color-main-pink;
$theme-pink-support-complement: $color-main-pink;
$theme-pink-signal: $color-main-pink;

$theme-default-background-color: $color-main-grey;
$theme-default-background: $color-main-grey;
$theme-default-background-fade: $color-main-grey;
$theme-default-background-fade-fixed: $color-main-grey;
$theme-default-color: $color-main-grey;
$theme-default-background-reversed: $color-main-grey;
$theme-default-support: $color-main-green;
$theme-default-support-complement: $color-main-green;
$theme-default-signal: $color-main-green;

@mixin themed {
  @content;

  body.theme-yellow & {
    @content;
  }
  body.theme-purple & {
    @content;
  }
  body.theme-blue & {
    @content;
  }
  body.theme-orange & {
    @content;
  }
  body.theme-green & {
    @content;
  }
  body.theme-pink & {
    @content;
  }
}

// main background colors

@mixin themed-main($selector: '&', $body-filter:'') {
  body {
    background-color: $theme-default-background-color;
  }

  body.theme-yellow#{$body-filter} #{$selector} {
    background-color: $color-main-yellow;
  }
  body.theme-purple#{$body-filter} #{$selector} {
    background-color: $color-main-pink;
  }
  body.theme-blue#{$body-filter} #{$selector} {
    background-color: $color-main-blue;
  }
  body.theme-orange#{$body-filter} #{$selector} {
    background-color: $color-main-red;
  }
  body.theme-green#{$body-filter} #{$selector} {
    background-color: $color-main-grey;
  }
  body.theme-pink#{$body-filter} #{$selector} {
    background-color: $color-main-pink;
  }
}

// support colors

@mixin themed-support {
  background-color: $theme-default-support;

  body.theme-yellow & {
    background-color: $theme-yellow-support;
  }
  body.theme-purple & {
    background-color: $theme-purple-support;
  }
  body.theme-blue & {
    background-color: $theme-blue-support;
  }
  body.theme-orange & {
    background-color: $theme-orange-support;
  }
  body.theme-green & {
    background-color: $theme-green-support;
  }
  body.theme-pink & {
    background-color: $theme-pink-support;
  }
}

// signal colors

@mixin themed-signal {
  background-color: $theme-default-signal;

  body.theme-yellow & {
    background-color: $theme-yellow-signal;
  }
  body.theme-purple & {
    background-color: $theme-purple-signal;
  }
  body.theme-blue & {
    background-color: $theme-blue-signal;
  }
  body.theme-orange & {
    background-color: $theme-orange-signal;
  }
  body.theme-green & {
    background-color: $theme-green-signal;
  }
  body.theme-pink & {
    background-color: $theme-pink-signal;
  }
}

// for icons

@mixin themed-signal-color {
  color: $theme-default-signal;

  body.theme-yellow & {
    color: $theme-yellow-signal;
  }
  body.theme-purple & {
    color: $theme-purple-signal;
  }
  body.theme-blue & {
    color: $theme-blue-signal;
  }
  body.theme-orange & {
    color: $theme-orange-signal;
  }
  body.theme-green & {
    color: $theme-green-signal;
  }
  body.theme-pink & {
    color: $theme-pink-signal;
  }
}

@mixin themed-signal-fill {
  fill: $theme-default-support;

  body.theme-yellow & {
    fill: $theme-yellow-support;
  }
  body.theme-purple & {
    fill: $theme-purple-support;
  }
  body.theme-blue & {
    fill: $theme-blue-support;
  }
  body.theme-orange & {
    fill: $theme-orange-support;
  }
  body.theme-green & {
    fill: $theme-green-support;
  }
  body.theme-pink & {
    fill: $theme-pink-support;
  }
}

@mixin themed-support-color {
  color: $theme-default-support;

  body.theme-yellow & {
    color: $theme-yellow-support;
  }
  body.theme-purple & {
    color: $theme-purple-support;
  }
  body.theme-blue & {
    color: $theme-blue-support;
  }
  body.theme-orange & {
    color: $theme-orange-support;
  }
  body.theme-green & {
    color: $theme-green-support;
  }
  body.theme-pink & {
    color: $theme-pink-support;
  }
}

// box-shadow colors
// NOTE: we can probably lose these after the redesign is finished
$default-box-shadow-inset: 0;
@mixin themed-box-shadow($opacity: 0.5,
$hoff: $default-box-shadow-h-offset,
$voff: $default-box-shadow-v-offset,
$blur: $default-box-shadow-blur,
$spread: $default-box-shadow-spread,
$inset: $default-box-shadow-inset) {
  box-shadow: rgba($theme-default-color, $opacity) $hoff $voff $blur $spread;

  body.theme-yellow & {
    box-shadow: rgba($color-main-yellow, $opacity) $hoff $voff $blur $spread;
  }
  body.theme-purple & {
    box-shadow: rgba($color-main-pink, $opacity) $hoff $voff $blur $spread;
  }
  body.theme-blue & {
    box-shadow: rgba($color-main-blue, $opacity) $hoff $voff $blur $spread;
  }
  body.theme-orange & {
    box-shadow: rgba($color-main-red, $opacity) $hoff $voff $blur $spread;
  }
  body.theme-green & {
    box-shadow: rgba($color-main-green, $opacity) $hoff $voff $blur $spread;
  }
  body.theme-pink & {
    box-shadow: rgba($color-main-pink, $opacity) $hoff $voff $blur $spread;
  }
}
