
//
// Define z-index layers
//

$layer-lightbox: 1337;
$layer-header: 700;
$layer-menu-overlay: 600;
$layer-main-menu: 600;
$layer-special: 400;
$layer-navigation: 300;
$layer-content-widget: 200;
$layer-content: 100;
