/* For a Landing page */

body.simple-overview-page.page-landing.page-landing {
  .c-overview-container {
    background: transparent;
  }

  .page-overview-title {
    @include transform(translateY(calc(-1 * 100%)));
    background: transparent;
    padding: 20px 0;

    &::after {
      content: none;
    }
  }

  .page-overview-section {
    background: transparent;

    @include below($breakpoint-mobile) {
      margin-top: 40px;
    }
  }

  .page-sections {
    background: transparent;

    // New header type, somewhat hardcoded, would be nice to generalise
    margin-top: 450px;
    padding-top: 0;

    @include below($breakpoint-full-logo) {
      // New header type, somewhat hardcoded, would be nice to generalise
      margin-top: -100%;
      padding-top: 130%;
    }

    @include below($breakpoint-landscape) {
      // New header type, somewhat hardcoded, would be nice to generalise
      margin-top: -100%;
      padding-top: calc(120% + 120px);
    }

    .inner-margined {
      background: transparent;
    }
  }

  .page-share {
    .c-share-button::after {
      background: $color-main-white;
    }
  }

  .page-upsell {
    background-color: $color-main-black;
    color: $color-main-white;
  }

  .section-intermezzo {
    background: transparent;
  }

  &.landing-summer {
    .page-header {
      height: 100vh;
      min-height: 100vh;
    }

    .page-header-image {
      height: 100vh;
    }

    @include keyframes(summer-circle-zoom-in) {
      0% {
        @include transform(none);
      }

      20% {
        @include transform(scale(2.4)); // 1.6
      }

      35% {
        @include transform(scale(2.25)); // 1.5
      }

      65% {
        @include transform(scale(2.25)); // 1.5
      }

      85% {
        @include transform(scale(3.55)); // 2.1
      }

      100% {
        @include transform(scale(3.35)); // 2

        fill-opacity: 1;
      }
    }

    #svg-summer {
      background-color: #1ba3f8;
      color: #fee434;
      height: 100%;
      width: 100%;

      @include transform(none);
    }
  }

  &.is-ie #svg-summer {
    @include animation(summer-circle-zoom-in 4s ease-in-out 1s 1 forwards);
    @include transform-origin(50% 65%);
  }

  &:not(.is-ie) #svg-summer {
    circle {
      @include animation(summer-circle-zoom-in 4s ease-in-out 1s 1 forwards);
      @include transform-origin(50% 50%);

      -moz-transform-origin: 50% 65% !important; // Firefox hack
    }
  }
}
