@import "../base/layout";

body {
  overflow-x: hidden;
}

.overlay-visible {
  overflow: hidden;
}

.page-wrapper {
  background-color: $color-main-grey;
  position: relative;
  z-index: 1;
}

.page-left-spacer {
  float: left;
  width: $logosize;
  height: 20px; // certain height is needed...
  max-width: $logomaxwidth;

  @include below($breakpoint-mobile) {
    width: 20px;
  }

  body.simple-overview-page & {
    //width: 170px; // check this, elastic?
    display: none;
  }
}

.page-sections {
  // this container also contains the left bar with colour filling...

  @include themed-main;
  position: relative;

  body:not(.searchresults):not(.theme-overview-page):not(.simple-overview-page):not(.no-header-visual) & {
    margin-top: $header-with-image-size;

    @include below($breakpoint-mobile) {
      margin-top: $header-with-image-size-mobile;
    }
  }

  z-index: 3;

  body.simple-overview-page & {
    background-color: $color-main-grey;

    margin-top: $logosize;

    @include above($breakpoint-maxlogowidth) {
      margin-top: $logomaxwidth;
    }

    @include below($breakpoint-mobile) {
      margin-top: $header-height-simple-overview-page;
    }
  }

  body.eventlist.simple-overview-page & {
    @include below($breakpoint-mobile){
      margin-top: $header-height-eventlist;
    }
  }

  body.simple-overview-page.has-visual & {
    margin-top: $header-with-image-size-simple;

    @include below($breakpoint-mobile) {
      margin-top: $header-with-image-size-mobile;
    }
  }

  @include clearfix();
}

.page-sections,
.page-upsell {
  .inner-margined {
    body & {
      background-color: $color-main-white;
    }

    float: left;
    margin-top: -92px;
    max-width: (100% - $logosize); // todo check this later, might be too big
    width: 100%;

    body.simple-overview-page & {
      margin-top: 0;
      max-width: $max-width; // 940
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      float: none;
    }
    body.theme-overview-page & { // ontdek overzicht
      margin-top: 0;
      max-width: none; // Constrain on page-sections rather
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      float: none;
    }

    @include above($breakpoint-maxlogowidth) {
      max-width: calc(100% - #{$logomaxwidth});
    }

    @include below($breakpoint-mobile) {
      margin-top: -42px;
      max-width: calc(100% - 20px);
    }
  }
}

.page-sections {
  .page-previous-next {
    display: none; //temporary, so it doesn't fek with my steez'

    @include content-wrapper-absolute;

    .page-previous-next-inner {
      $icon-height: $grid-tile;
      @include content-wrapper;
      position: relative;
      left: $icon-height;
      top: -$grid-tile;
      z-index: $layer-navigation;

      .page-previous, .page-next {
        a {
          @include icon-button;
        }
      }

      .page-previous {
        float: left;
        margin-left: $grid-tile;
      }

      .page-next {
        float: right;
      }
    }
  }

  @include below($breakpoint-landscape) {
    .page-previous-next {
      .page-previous-next-inner {
        $icon-height: $grid-tile-landscape;
        left: 0;
        top: -$grid-tile-landscape;

        .page-previous {
          margin-left: $grid-tile-landscape;
        }

        .page-next {
          float: left;
        }
      }
    }
  }

  @include below($breakpoint-mobile) {
    margin-top: $header-with-image-size-mobile;
  }
}

.page-top-scroller {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  z-index: $layer-main-menu;

  @include transition(opacity 300ms, visibility 300ms);

  @include hover {
    opacity: 1 !important;
  }

  a {
    @include text-icon-button($icon: $icon-data-triangle-up);
    background-color: $color-main-white;
    color: $color-main-black;

    @include hover {
      background: $color-main-black !important;
    }
  }
}

// page without header visual

.no-header-visual:not(.exhibition-page):not(.simple-overview-page) {
  $min-logo-width: 100px;

  .inner-margined {
    min-height: 600px;

    @include below($breakpoint-mobile) {
      min-height: 200px;
    }
  }

  // Custom main header
  .page-sections {
    left: 0;
    margin-top: 0;
    padding-top: $logosize / 2;
    top: 0;

    .inner-margined {
      margin-top: 0;
    }
  }

  .page-share {
    padding-bottom: 80px;
  }

  .page-title {
    padding-bottom: $margin-small;
  }

  @include above($breakpoint-maxlogowidth) {
    .page-sections {
      left: 0;
      padding-top: $logomaxwidth / 2;
    }
  }

  @include below($breakpoint-mobile) {
    .page-sections {
      padding-top: $min-logo-width;

      .page-left-spacer {
        width: 20px;
      }

      .inner-margined {
        max-width: calc(100% - 20px);
        margin-top: 72px;
      }
    }
  }
}

.section-iframe {
  @include max-width;
  @include grid-padding;
}

.content-iframe {
  margin-left: -50px;
  height: 590px;

  overflow-y: hidden;

  @include mobile {
    overflow-x: scroll;
  }

  //height: 470px;
  iframe, img {
    width: 760px;
    height: 470px;
  }
  @include tablet-portrait {
    overflow-x: hidden;
    //height: 445px;
    iframe, img {
      width: 720px;
      height: 590px;
    }
    > figcaption {
      margin: 0 100px;
    }
  }

  iframe {
    height: 590px;
  }
  @include below($breakpoint-mobile) {
    width: 100%;
    height: 590px;
    overflow-x: scroll;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.page-section.section-iframe {
  padding-bottom: 40px;
  padding-top: 40px;

  & + .section-fitting {
    margin-top: -30px;
  }
}

.wedstrijd-page {
  .discover-list-container {
    @include grid-padding;
  }
}
