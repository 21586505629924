
$icon-download: '\e000';
$icon-close: '\e001';
$icon-full-arrow-left: '\e002';
$icon-full-arrow-right: '\e003';
$icon-text-arrow-left: '\e004';
$icon-text-arrow-right: '\e005';
$icon-search: '\e006';
$icon-check: '\e007';
$icon-camera: '\e90f';
$icon-down-arrow: '\e008';
$icon-cabaret: '\e009';
$icon-buffet: '\e00a';
$icon-reception: '\e00b';
$icon-theater: '\e00c';
$icon-diner: '\e00d';
$icon-u-shape: '\e00e';
$icon-phone: '\e00f';
$icon-mail: '\e010';
$icon-clock: '\e011';
$icon-big-down-arrow: '\e012';

$icon-tiktok: '\e914';
$icon-pinterest: '\e601';
$icon-facebook: '\e602';
$icon-youtube: '\e603';
$icon-calendar: '\e604';

$icon-party: '\e900';

$icon-chevron-left: '\e901';
$icon-chevron-right: '\e902';

$icon-arrow-down: '\e903';
$icon-instagram: '\e904';
$icon-pinterest2: '\e905';
$icon-twitter2: '\e906';
$icon-facebook2: '\e907';
$icon-close-2: '\e908';
$icon-arrow-up: '\e909';
$icon-play: '\e90a';
$icon-print: '\e913';
$icon-arrow-right: ';\e90b';
$icon-arrow-left: '\e90c';
$icon-hamburger2: '\e90d';
$icon-hamburger: '\e90e';
$icon-triangle-up: '\e910';
$icon-triangle-left: '\e911';
$icon-triangle-right: '\e912';

$icon-data-arrow-right: "arrow-right" $icon-arrow-right 100;
$icon-data-triangle-right: "triangle-right" $icon-triangle-right 32;
$icon-data-triangle-left: "triangle-left" $icon-triangle-left 32;
$icon-data-triangle-up: "triangle-up" $icon-triangle-up 32;
$icon-data-close: "close" $icon-close-2 50;
$icon-data-facebook: "facebook" $icon-facebook 70;
$icon-data-tiktok: "facebook" $icon-tiktok 60;
$icon-data-instagram: "facebook" $icon-instagram 50;
$icon-data-mail: "facebook" $icon-mail 55;
$icon-data-hamburger: "hamburger" $icon-hamburger 40;
$icon-data-print: "print" $icon-print 40;
$icon-data-search: "search" $icon-search 50;
$icon-data-chevron-left: "chevron-left" $icon-chevron-left 60;
$icon-data-chevron-right: "chevron-right" $icon-chevron-right 60;

$icon-data-big-triangle-right: "big-triangle-right" $icon-triangle-right 60;
$icon-data-big-triangle-left: "big-triangle-left" $icon-triangle-left 60;

$button-size: 50px;
$button-size-landscape: 50px;
$button-size-portrait: 50px;
$button-size-mobile: 50px;

//
// Icon library
//
// arguments:
//  <"name/slug"> <icon code> <percentage at which size icon should be rendered>
//
$icons: (
        "arrow-down" $icon-arrow-down 60,
        "arrow-left" $icon-arrow-left 100,
        $icon-data-arrow-right,
        "arrow-up" $icon-arrow-up 100,
        $icon-data-close,
        $icon-data-facebook,
        $icon-data-hamburger,
        "instagram" $icon-instagram 80,
        "mail" $icon-mail 70,
        "pinterest" $icon-pinterest2 80,
        $icon-data-search,
        $icon-data-triangle-right,
        "twitter" $icon-twitter2 45,
        $icon-data-big-triangle-right,
        $icon-data-big-triangle-left,
        $icon-data-chevron-left,
        $icon-data-chevron-right,
);

$button-icons: (
);

@mixin add-icon($pua, $location:'after') {
  &::#{$location} {
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: icons;
    content: " #{$pua}";
    @content;
  }
}

@mixin base-icon-raw($icon, $bg-color:$color-main-pink, $fg-color:$color-main-black, $location:'after') {
  @include icon-color-variation($bg-color, $fg-color, $location: $location);
  @include add-icon($icon, $location: $location) {
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: bottom;
    @content;
  }
;
}

@mixin base-icon($icon-tuple, $size, $bg-color:$color-main-pink, $fg-color:$color-main-black, $ratio:100,
                 $location:'after') {
  @include icon-size-variation($icon-tuple, $size, $ratio, $location: $location);
  @include base-icon-raw(
                  nth($icon-tuple, 2),
                  $bg-color,
                  $fg-color,
          $location: $location
  ) {
    @content;
  }
}

@mixin icon-variations($size:$button-size, $bg-color:$color-main-pink, $fg-color:$color-main-black, $ratio:100,
                       $icon-tuple: false) {
  @if $icon-tuple {
    @include base-icon($icon-tuple, $size, $bg-color, $fg-color, $ratio);
  } @else {
    @each $icon in $icons {
      $name: nth($icon, 1);
      $value: nth($icon, 2);
      $icon-size: nth($icon, 3);

      &.v-#{$name} {
        @include base-icon($icon, $size, $bg-color, $fg-color, $ratio);
      }
    }
  }
}

@mixin icon-color-variation($bg-color:false, $fg-color:false, $location:'after') {
  &::#{$location} {
    @if ($bg-color) {
      background-color: $bg-color;
    }
    @if ($fg-color) {
      color: $fg-color;
    }
  }
}

@mixin icon-size-variation-calculator($icon-tuple, $size, $button-size, $ratio, $mode) {
  $icon-size: nth($icon-tuple, 3) * $size * $ratio / 100 / 100;

  @if ($icon-size) {
    font-size: $icon-size;
  } @else {
    font-size: $size;
  }
;
  height: $size;
  @if $mode == "line-height" {
    line-height: $size;
  } @else if $mode == "padding" {
    padding-top: ($size - $icon-size) / 2;
  }
  width: $size;
}

@mixin icon-size-variation($icon-tuple, $size, $ratio:100, $mode:"line-height", $location:"after") {
  &::#{$location} {
    @include icon-size-variation-calculator($icon-tuple, $size, $button-size, $ratio, $mode);
    @content;
  }

  @include below($breakpoint-landscape) {
    &::#{$location} {
      @include icon-size-variation-calculator($icon-tuple, $size, $button-size-landscape, $ratio, $mode);
    }
  }

  @include below($breakpoint-portrait) {
    &::#{$location} {
      @include icon-size-variation-calculator($icon-tuple, $size, $button-size-portrait, $ratio, $mode);
    }
  }

  @include below($breakpoint-mobile) {
    &::#{$location} {
      @include icon-size-variation-calculator($icon-tuple, $size, $button-size-mobile, $ratio, $mode);
    }
  }
}

.c-pink-icon {
  @include icon-variations;
}

@mixin arrow-before {
  &::before {
    color: $color-main-black;
    content: "#{$icon-full-arrow-right}";
    font-family: icons;
    font-size: 10px;
    padding-right: 10px;
    position: relative;
    top: -1px;
    // to remover hover underline
    display: inline-block;
  }
}

@mixin facility-icon($icon) {
  &::before {
    font-family: icons;
    content: $icon;
    font-size: 40px;
    float: left;
    padding: 10px 10px 10px 0;
  }
}
