.exhibition-partners, .partners {
  @include clearfix;
  @include content-section-block;
  @include max-width;
  @include typography-paragraph;
  margin-top: $grid-tile;
  display: flex;
  flex-wrap: wrap;

  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  ul {
    float: left;
    clear: left;
    min-height: 75px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .sponsor-partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;

    li {
      display: inline-block;
      margin-right: 20px;
      vertical-align: middle;
    }

    &:last-child {
      margin-right: 0;
    }

    li + li {
      margin-left: 15px;
      @include mobile {
        margin-left: 0;
      }
    }
  }

  .cooperative-partners {
    clear: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      margin-bottom: 0px;
    }

    li {
      color: $color-secondary-grey;
      display: inline-block;
      white-space: nowrap;
      vertical-align: middle;
    }

    li + li {
      margin-left: 15px;
    }
  }
}

// in-text partner
.partner {
  //@include max-width;
  @include grid-padding;
  @include typography-paragraph;
  padding-bottom: 40px;

  a {
    display: block;
    width: 100px;
  }
  img {
    float: left;
    padding: 0;
    @include mobile {
      padding:20px 0;
    }
  }

  div.e-text {
    @include rich-text;
    margin-left: 100px;

    h4 {
      @include typography-header-5;
    }
  }
}