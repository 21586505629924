//extra breakpoints
$breakpoint-agenda: 680px;

//widths
$asidewidth: 290px;
$thumbnailwidth: 160px;
$bigthumbnailwidth: 300px;

// page header

.page-overview-agenda {
  @include page-overview-header;

  .agenda-day {
    // For multiline titles the breakpoint to the smaller font is increased
    @include typography-header-2-multiline;
    font-size: 50px;
    line-height: 52px;
    text-transform: inherit;
    &::first-letter {
      text-transform: uppercase;
    }

  }

  .agenda-title {
    // For multiline titles the breakpoint to the smaller font is increased
    @include typography-header-4-multiline;
    text-transform: uppercase;
  }
}

.eventlist {
  .page-overview-title {
    &:first-letter {
      text-transform: capitalize;
    }
  }
}

// aside

.agenda-aside {
  font-size: $default-font-size;
  width: $asidewidth;
  margin-left: $margin;
  max-width: 100%; // for mobile
  float: right;
  position: relative;

  @include below($breakpoint-agenda) {
    float: none !important;
    margin: ($margin - $side-padding-mobile) auto $margin;
  }

  .section-related {
    @include rich-text;
    background: $color-main-white;
    padding: 20px;
    margin: 35px 0;

    .section-related__title {
      hyphens: none;
      font-size: 18px;
      line-height: 25px;
      padding: 0 0 15px 0;
      margin-bottom: 5px;
      font-weight: 400;
      @include typography-header-4;
    }


    @include below($breakpoint-agenda) {
      display: none;
    }
    .content-list-links {
      li {
        position: relative;
        padding-left: 15px;

        &::before {
          top: 5px;
        }
      }
    }
  }
}

.highlight {
  .page-representation-simple {
    margin-right: 0;
    margin-left: 0;
  }

  @include below($breakpoint-agenda) {
    display: none;
  }
}

// datepicker

#datepicker {
  @include typography-default;
  background-color: $color-main-green;
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
  position: relative;

  .ui-datepicker-title {
    @include typography-header-4;
    text-align: center;
    margin: 10px 0;
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    position: absolute;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 0;
    top: 13px;
    cursor: pointer;

    &:before {
      display: inline-block;
      font-family: icons;
      font-size: $default-font-size;
      line-height: 40px;
    }
  }

  .ui-datepicker-prev {
    left: 5px;
    &:before {
      content: '#{$icon-text-arrow-left}';
    }
  }

  .ui-datepicker-next {
    right: 5px;
    &:before {
      content: '#{$icon-text-arrow-right}';
    }
  }

  .ui-priority-secondary {
    color: $color-main-grey;
  }

  table {
    width: 100%;
    color: $color-main-black;
    border-spacing: 1px;
  }

  th {
    color: $color-main-white;
    height: 30px;
  }

  td {
    box-sizing: border-box;
    width: 14%;
    height: 40px;
    background-color: $color-main-white;
    text-align: center;
    padding: 0;
    font-size: 0;
    &.ui-state-closed {
      a {
        background-color: $color-main-grey;
      }
    }

    > a {
      @include typography-default;
      display: inline-block;
      text-decoration: none;
      line-height: 40px;
      width: 100%;
      height: 100%;
      color: $color-main-black;

      &.ui-state-highlight {
        background-color: $color-main-grey;
      }

      &.ui-state-active {
        background-color: $color-main-green;
      }

      @include hover {
        background-color: $color-main-green;
      }
    }
  }

}

#event-filter {
  display: none;
}

// agenda content & agenda items

.agenda-items, .agenda-content {
  float: right;
  @include calc(width, "100% - (#{$asidewidth} + #{$margin})");

  @include below($breakpoint-agenda) {
    width: 100%;
  }
}

.agenda-content {
  background-color: $color-main-white;
  margin-bottom: $margin;

  .featured-image {
    width: $bigthumbnailwidth;
    float: left;

    .e-mobile {
      display: none;
    }

    img {
      max-width: 100%;
    }

    @include below(870px) {
      width: 100%;
    }

    @include below($breakpoint-agenda) {
      .e-desktop {
        display: none;
      }

      .e-mobile {
        display: block;
      }
    }
  }

  .label {
    @include coloured-label-small;
    margin-bottom: 12px;
  }

  .content {
    @include rich-text;
    @include calc(width, "100% - (#{$bigthumbnailwidth})");
    padding: $margin-small;
    float: left;

    @include below(870px) {
      width: 100%;
    }
  }

  h2 {
    @include typography-header-4;
    padding-bottom: 5px;
  }

  p {
    @include typography-paragraph;
    margin-bottom: 10px
  }

  a {
    @include arrow-before;
  }
}

.agenda-item {
  @include clearfix;
  background-color: $color-main-white;
  border-top: 2px solid $color-main-grey;
  position: relative;
  padding: 20px;

  .event-times {
    .event-time {
      @include coloured-label-small;
      margin-bottom: 12px;
    }
  }

  .event-thumbnail {
    width: 160px;
    float: left;
    margin: 0 20px 20px 0;

    .e-mobile {
      display: none;
    }

    img {
      max-width: 100%;
    }

    @include below($breakpoint-agenda) {
      width: 100%;

      .e-desktop {
        display: none;
      }

      .e-mobile {
        display: block;
      }
    }
  }

  > span {
    // time
    @include themed-signal;
    color: $color-main-white;
    padding: 5px 10px;

    position: absolute;
    right: 535px;
    top: 60px;
  }
}

.agenda-item-info {
  @include rich-text;
  @include calc(width, "100% - (#{$thumbnailwidth} + #{$margin-small})");
  float: left;

  @include below($breakpoint-agenda) {
    width: 100%;
  }

  > h2 {
    @include typography-header-4;
    padding-bottom: 3px;
  }

  // meta-data
  > ul {
    @include typography-default;
    list-style-type: none;
    color: $color-secondary-grey;
    padding-bottom: 5px;

    > li {
      display: inline-block;

      &:after {
        content: " | ";
      }
      &:last-child:after {
        content: '';
      }
    }
  }

  > p {
    @include typography-paragraph;
    margin: 0 0 10px 0;
  }

  > a {
    @include arrow-before;
  }

  &.closed {
    padding: 5px 15px;

    > h2 {
      padding-bottom: 10px;
    }

    .button-closed {
      @include arrow-before;
    }
  }
}

.agenda-item-details {
  border-top: 3px solid $color-main-grey;
  padding-top: 20px;
}

.agenda-link {
  @include rich-text;
}

// detail page

.external-block {
  @include content-section-block;
  padding-top: 15px;
  padding-bottom: 10px;
}

.external-location.activity-detail {
  @include coloured-label-small;
}