$form-background-color: $color-lightest-grey;
$form-border-color: $color-secondary-grey;
$form-error-color: $color-main-red;

@mixin form-errors {
  .formrow.errors & {
    @content;
  }
  &.errors {
    @content;
  }
}

.wedstrijd-page form,
.form form {
  @include rich-text;

  @include content-section-block;
  padding-top: 0px;
  padding-bottom: 80px;

  font-family: $font-campton-book;

  .form__title {
    @include typography-header-4;
    margin: 10px 0px;
    padding-left: 40px;
    margin-bottom: 10px;
    margin-top: -25px;
    display: block;
  }

  .form__sub-title {
    @include typography-paragraph;
    margin-bottom: 25px;
    padding-left: 40px;  
    display: block;
  }

  .form__e-mail-field {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 20px;
    display: block;
  }

  .formrow ul li:before {
    display: none;
  }
  .formrow ul {
    overflow: visible;
  }
  ul.errorlist {
    display: block;
    position: relative;
    //right: -260px;
    top: 0;
    background-color: $form-error-color;
    color: $color-main-white;
    list-style-type: none;
    margin: 0;
    padding: 8px 10px;
    width: 220px;
    li {
      padding-left: 0;
    }
    width: 200px;
  }

  select {
    margin-top: 10px;
  }

  input[type=text], input[type=number], textarea {
    box-sizing: border-box;
    border-radius: 0;
    @include appearance(none);
    box-shadow: 0 0 0;
    display: inline-block;
    font-family: $corbel;
    height: 40px;
    width: 320px;
    @include below($breakpoint-mobile) {
      width: 100%;
    }
    font-size: 19px;
    line-height: 19px;
    padding: 0 12px;
    color: $color-main-black;
    background-color: $form-background-color;
    border: 2px solid $color-main-grey;

    &:focus {
      // outline: none;
    }
  }

  .formrow.errors {
    input[type=text], input[type=number], textarea {
      border-color: $form-error-color;
    }
  }

  .search-input {
    position: relative;
    display: inline-block;
    //@include prefix-icon($icon-search);
    &:before {
      position: absolute;
      left: 10px;
      top: 12px;
      color: $color-main-grey;
    }
    > input {
      width: 100%;
      padding-left: 40px;
    }
  }

  textarea {
    height: 165px;
    width: 520px;
    @include below($breakpoint-mobile) {
      width: 100%;
    }
    padding: 5px;
    resize: none;
  }

  input[type=text], input[type=number], textarea, select {
    &:focus {
      border-color: $color-main-grey;
    }
  }

  label {
    color: $color-main-black;
    width: 200px;
    display: inline-block;
    vertical-align: top;
    padding-top: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    &[for] {
      cursor: pointer;
    }
    a {
      @include themed-support-color;
      &:visited {
        @include themed-support-color;
      }
      @include hover {
        @include themed-signal-color;
      }
    }
    @include below($breakpoint-mobile) {
      width: 100%;
      display: block;
    }
  }

  .radio label, .checkbox label {
    width: auto;
    cursor: pointer;

    .ez-checkbox {
      float: left;
      margin-right: 10px;
    }
  }

  &:not(.form--no-asterisks).required > label:after {
    display: inline;
    content: " *";
  }

  p.hint {
    color: $color-secondary-grey;
    margin-bottom: 10px;
  }

  .break {
    height: 3px;
    background-color: $color-main-grey;
    margin: 30px 0;
  }

  .date {
    .dropdown:nth-child(2) {
      width: 65px;
    }
    .dropdown:nth-child(3) {
      width: 140px;
    }
    .dropdown:nth-child(4) {
      width: 95px;
    }
    
    div {
      position: relative;

      select {
   
      }

      label {
        background: red;
        font-size: 8px;
        position: absolute;
        line-height: 0;
        padding: 0;
        margin: 0;
        top: 20px;
        left: 9px;
        z-index: -1;
      }
    }
  }

  .checkbox {
    margin-left: 195px;
    margin-top: 5px;

    @include below($breakpoint-mobile) {
      margin-left: 0;
    }

    input {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .ez-checkbox, .ez-radio {
    position: relative;
    top: -2px;
  }

  .radio-list, .checkbox-list {
    > label {
      padding-top: 0;
    }
    > ul {
      margin-left: -5px;
      margin-bottom: 0;
      list-style-type: none;
      display: inline-block;
      > li {
        display: block;
        padding: 0;
        > label {
          padding: 0;
          width: auto;
        }
      }
    }
    > table {
      display: inline-block;
      h5 {
        padding-top: 12px; // to have the same height as the input
        > label {
          margin-right: 70px;
          padding: 0;
          width: auto;
          > input {
            margin-right: 5px;
          }
        }
      }
      td > input {
        width: 65px;
      }
    }
    &.richtext-labels li {
      > label {
        width: 320px;
        @include below($breakpoint-mobile) {
          width: 100%;
        }
        h4, p {
          padding-left: 40px;
        }
        > .ez-checkbox, > .ez-radio {
          float: left;
        }
      }
    }
  }

  .formrow {
    margin-bottom: 10px;
    min-height: 40px;
    position: relative;
  }
  
  .submit-row {
    margin-top: 20px;
    button {
      top: 0;
      @include text-icon-button;
    }
  }

  fieldset {
    @include appearance(none);
    border: none;
    outline: none;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: solid 3px $color-main-grey;
  }

  .e-collapsible {
    margin-left: 200px;
    margin-bottom: 20px;
    width: 320px;

    @include below($breakpoint-portrait) {
      margin-left: 0;
      width: 100%;
    }

    th:first-child {
      width: 50px;
    }

    th:nth-child(2) {
      width: 320px - 50px;
      @include below($breakpoint-portrait) {
        width: calc(100% - 50px);
      }
    }

    input {
      width: 320px - 50px;
      @include below($breakpoint-portrait) {
        width: calc(100% - 50px);
      }
    }
  }

  @mixin placeholder {
    color: $color-main-grey;
    font-family: $corbel;
    font-size: 19px;
  }

  input::-webkit-input-placeholder {
    @include placeholder;
  }

  input::-moz-placeholder {
    @include placeholder;
  }

  input:-moz-placeholder {
    @include placeholder;
  }

  input:-ms-input-placeholder {
    @include placeholder;
  }

  .checkbox-list,
  .radio-list {
    input + label {
      @include below($breakpoint-mobile) {
        width: auto;
        display: inline-block;
      }
    }
  }
}

.checkbox-list.errors {
  padding: 10px;
  outline: 2px solid #FF3C00;
}

.formrow.date {

  div {
    display: inline-block;
    position: relative;

    label {
      width: auto;
      display: block;
      
    }

    select {
      display: block;
      margin-right: 10px;
    }

  }
} 

.page-section .page-section .form-content form .formrow {
  ::placeholder {
    color: #707070;
    opacity: 1; /* Firefox */
  }  
}

//
//
//$ezmark-clean-up: true;
//$ezmark-spacing: 40px;
//$ezmark-layout: vertical;
//@import "sprites/ezmark/*.png";
//
//// ez-mark
//.ez-checkbox, .ez-radio {
//  width: 30px;
//  height: 30px;
//  display: inline-block;
//  cursor: pointer;
//  > input {
//    cursor: pointer;
//  }
//}
//
//.ez-hide {
//  @include opacity(0);
//}
//
//// checkbox
//.ez-checkbox {
//  @include ezmark-sprite(checkbox);
//}
//
//.ez-checked {
//  @include ezmark-sprite(checkbox-checked);
//}
//
//.ez-checkbox.focus {
//  @include ezmark-sprite(checkbox-focus);
//}
//
//.ez-checked.focus {
//  @include ezmark-sprite(checkbox-checked-focus);
//}
//
////radio
//.ez-radio {
//  @include ezmark-sprite(radio, $offset-x: 5px, $offset-y: 5px);
//}
//
//.ez-selected {
//  @include ezmark-sprite(radio-checked, $offset-x: 5px, $offset-y: 5px);
//}
//
//.ez-radio.focus {
//  @include ezmark-sprite(radio-focus, $offset-x: 5px, $offset-y: 5px);
//}
//
//.ez-selected.focus {
//  @include ezmark-sprite(radio-checked-focus, $offset-x: 5px, $offset-y: 5px);
//}
