@mixin overview-block-padding {
  clear: left;
  @include below($breakpoint-mobile-overview-blocks) {
    width: 71%;
    clear: none;
    float: left;
    padding: $grid-tile-mobile/2 $grid-tile-mobile/2 15px;
  }
  @include below($breakpoint-landscape) {
    padding: 15px 20px 15px 20px;
  }
  @include above($breakpoint-portrait) {
    padding: 15px 20px 15px 20px;
  }
}

//body.simple-overview-page & {
.page-overview-title {
  @include typography-header-2;
  @include page-overview-header;
}

.c-overview-container {

  body & {
    background-color: $color-main-grey;
  }

  @include below($max-width) {
    text-align: center;
  }
}

.c-page-children,
.page-overview-section { // this is the page sction
  body & {
    background-color: $color-main-grey;
  }

  padding-top: 35px;
  padding-bottom: 80px;
  font-size: 0; // inline blocks yo
  @include below($breakpoint-mobile-overview-blocks) {
    padding: 20px 20px;
  }
}

.page-campaign-section {
  padding-bottom: 0;

  @include below($breakpoint-mobile-overview-blocks) {
    padding-bottom: 0;
  }
}

.c-page-children {
  padding-top: 0;
}

.page-representation-simple, .page-representation {
  display: inline-block;
  width: 290px;
  height: $overview-block-height;
  margin-bottom: 36px;
  vertical-align: top;
  margin-right: 35px;

  text-align: left;

  @include below($max-width) {
    margin-left: 17px;
    margin-right: 17px;
  }

  @include below($breakpoint-mobile-overview-blocks) {
    height: auto;
    width: 100%;
    background: $color-main-white;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    float: left;
    max-width: 100%;

    &.e-desktop {
      display: block;
    }
    &.e-mobile {
      display: none;
    }
  }

  @include below($breakpoint-mobile-overview-blocks) {
    header {
      float: left;
      width: 29%;
    }
    img {
      &.e-desktop {
        display: none;
      }
      &.e-mobile {
        display: block;
        width: 100%;
        height: auto;
      }
    }

  }

  .clearfix {
    clear: both;
  }

  > a {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    background: $color-main-white;
    color: $color-main-black;
    overflow: hidden;
    @include transition(background 300ms, color 300ms);

    @include hover {
      text-decoration: none;
      background: $color-main-green;
      //color: $color-main-white;
      &:after {
        background: $color-main-green;
      }
    }

    &:after {
      content: "#{$icon-triangle-right}";
      font-family: icons;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      width: 25px;
      height: 25px;
      position: absolute;
      display: block;
      padding-top: 1px;
      bottom: 0;
      right: 0;
      color: $color-main-black;
      background: $color-main-green;
      clear: left;
    }
  }

  &.video-block {
    background: transparent;
    cursor: pointer;
    position: relative;

    @include add-icon($icon-play) {
      color: $color-main-black;
      position: absolute;
      font-size: 60px;
      left: calc(50% - 28px);
      top: calc(50% - 30px);
      @include transition(background 300ms, color 300ms);
      pointer-events: none;

      @include below($breakpoint-mobile-overview-blocks) {
        font-size: 40px;
        left: calc(50% - 18px);
        top: calc(50% - 20px);
      }
    }

    > a {
      background: transparent;

      &:after {
        display: none;
      }

      @include hover {
        background: transparent;
      }
    }

    @include hover {
      &:after {
        color: $color-main-green;
      }
    }

  }

  &.download-image {
    > a:after {
      content: "#{$icon-download}";
      width: 25px;
      height: 25px;
    }
  }

  .c-grid-intro {
    @include typography-default;
    @include overview-block-padding;
    color: $color-main-black;

    .subtitle {
      padding: 0;
      background: transparent;
      color: $color-secondary-grey;
      margin-top: -5px;
      margin-bottom: 5px;
    }

  }

  .external-location {
    clear: left;
    display: block;
    height: auto;
    background: $color-main-black;
    margin: 0;
    padding: 4px 20px 2px;
    position: relative;
    top: -22px;
    margin-bottom: -22px;
    @include below($breakpoint-mobile-overview-blocks) {
      padding-left: $grid-tile-mobile/2;
      padding-right: $grid-tile-mobile/2;
    }
    @include below($breakpoint-landscape) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include above($breakpoint-portrait) {
      padding-left: 20px;
      padding-right: 20px;
    }
    line-height: 100%;
    color: #fff;
    & + div {
      padding-top: 5px;
      height: 72px;
    }
  }

  h2 {
    @include typography-header-4;

    @include below($breakpoint-mobile-overview-blocks) {
      padding: 0 0 5px;
    }
    @include below($breakpoint-landscape) {
      padding: 0 0 5px;
    }
    @include above($breakpoint-portrait) {
      padding: 0 0 5px;
    }
  }
}

.page-representation-simple {
  &:nth-child(3n) {
    @include above($max-width) {
      margin-right: 0;
    }
  }
}

.link-wrapper {
  clear: both;
}

.exhibition-links {
  clear: both;
  padding-top: 20px;
  padding-bottom: 20px;
  @include below($breakpoint-mobile) {
    padding-bottom: 60px;
  }
  position: relative;
  z-index: $layer-content; //above header image fixed
  background-color: $color-main-grey;
  h3 {
    @include typography-header-3;
    margin-bottom: 20px;
  }
  a {
    display: inline-block;
    margin: 0 20px 20px 0;
    @include coloured-label($link: true);
    @include below($breakpoint-mobile) {
      margin: 0 5px 10px 0;
    }
  }
}
