.cookie-wall {
  background-color: $color-main-white;
  padding: 0 50px;

  &__context {
    font: 500 16px / 21px $font-campton-book;
    padding: 50px 0;
  }

  &__button {
    margin: 20px 0;
  }

  &__walled-content {
    display: none;
  }
}
