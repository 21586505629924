.c-search-widget {
  $icon-tuple: "" $icon-search 70;
  $icon-size: 30px;
  @include clearfix;

  .e-search-button {
    display: inline-block;
    float: left;
    position: relative;
    height: $icon-size;
    width: 30px;

    @include base-icon-raw($icon-search, $bg-color:transparent, $fg-color:$color-secondary-grey) {
      font-size: $icon-size * 0.6;
      line-height: $icon-size;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .e-input {
    @include appearance(none);
    @include typography-header-4;
    background-color: $color-main-black;
    border: none;
    color: $color-main-white;
    float: left;
    width: calc(100% - #{$icon-size});

    @include placeholder {
      color: $color-main-white;
    }

    &:focus, &.on-focus {
      outline: none;

      @include placeholder {
        color: $color-secondary-grey;
      }
    }
  }

  @include below($breakpoint-landscape) {
  }

  @include below($breakpoint-portrait) {
  }

  @include below($breakpoint-mobile) {
  }
}