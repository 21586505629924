//
// Base layout classes and mixins
//

$grid-tile: 60px;
$grid-tile-landscape: 60px; // optimized for ipad
$grid-tile-portrait: 40px; // optimized for ipad
$grid-tile-mobile: 40px; // TODO: probably different method

$gutter: 20px;
$gutter-landscape: 20px;
$gutter-portrait: 20px;
$gutter-mobile: 20px;

$column-count: 12;
$column-count-landscape: 12;
$column-count-portrait: 12;
$column-count-mobile: 12;

$side-padding-desktop: $gutter + $grid-tile;
$side-padding-mobile: $gutter-mobile;

// Logo
$logofactor: (250/1280) / 100;
$logosize: (250/1280) * 100%;
$logomaxwidth: 300px;
$logominsize: 100px;

// Breakpoint for when the max-width will be in effect
$breakpoint-maxlogowidth: 1536px; // Sick math version: #{$logomaxwidth / $logosize * 100%};

// Blocks, used for example on home page
$block-size: $logosize * 0.6;
$block-size-mobile: 100px;

// Header
$header-with-image-size: 620px;
$header-with-image-size-simple: 510px;
$header-with-image-size-mobile: 290px;
$header-with-image-size-simple-mobile: 235px;
$header-height-simple-overview-page: 160px;
$header-height-eventlist: 225px;

$max-width--small: 620px; // 2 * 300 + 1 * 20
$max-width: 940px;
$width: 100%;
$max-width--large: 1260px;  // 4 * 300 + 3 * 20

// Paddings & margins
$margin: 35px;
$margin-small: 20px;

@mixin max-width($center:false, $large:false) {
  @if $center {
    margin-left: auto;
    margin-right: auto;
  }
  @if $large {
    max-width: $max-width--large;
  } @else {
    max-width: $max-width;
  }
  width: $width;

  @if $large {
    @include below($breakpoint-large) {
      max-width: $max-width;
    }
  }

  @include below($breakpoint-medium) {
    max-width: $max-width--small;
  }
}

@mixin content-wrapper {
  @include below($breakpoint-portrait) {
    width: 100%;
  }
}

@mixin content-wrapper-absolute {
  @include content-wrapper;
  left: 0;
  position: absolute;
  right: 0;
}

@mixin content-column {
  width: 760px;

  @include below($breakpoint-landscape) {
    width: calc(100% - #{$grid-tile-landscape * 3});
  }

  @include below($breakpoint-portrait) {
    width: calc(100% - #{$grid-tile-portrait * 3});
  }

  @include below($breakpoint-mobile) {
    width: calc(100% - #{$grid-tile-mobile * 2});
  }
}

// Add padding or margin based on grid-tiles
@mixin grid-padding($columns:1, $mode:"padding") {
  @if $mode == "padding" {
    padding-left: $side-padding-desktop;
    padding-right: $side-padding-desktop;
  } @else if $mode == "margin" {
    margin-left: $side-padding-desktop;
    margin-right: $side-padding-desktop;
  } @else if $mode == "left" {
    left: $side-padding-desktop;
  }

  @include below($breakpoint-landscape) {
    @if $mode == "padding" {
      padding-left: $grid-tile-landscape + $gutter-landscape;
      padding-right: $grid-tile-landscape + $gutter-landscape;
    } @else if $mode == "margin" {
      margin-left: $grid-tile-landscape + $gutter-landscape;
      margin-right: $grid-tile-landscape + $gutter-landscape;
    } @else if $mode == "left" {
      left: $grid-tile-landscape + $gutter-landscape;
    }
  }

  @include below($breakpoint-portrait) {
    @if $mode == "padding" {
      padding-left: $grid-tile-portrait + $gutter-portrait;
      padding-right: $grid-tile-portrait + $gutter-portrait;
    } @else if $mode == "margin" {
      margin-left: $grid-tile-portrait + $gutter-portrait;
      margin-right: $grid-tile-portrait + $gutter-portrait;
    } @else if $mode == "left" {
      left: $grid-tile-portrait + $gutter-portrait;
    }
  }

  @include below($breakpoint-mobile) {
    @if $mode == "padding" {
      padding-left: $side-padding-mobile;
      padding-right: $side-padding-mobile;
    } @else if $mode == "margin" {
      margin-left: $side-padding-mobile;
      margin-right: $side-padding-mobile;
    } @else if $mode == "left" {
      left: $side-padding-mobile;
    }
  }
}

// return the grid padding width
@mixin grid-padding-width() {
  width: $grid-tile + $gutter;

  @include below($breakpoint-landscape) {
    width: $grid-tile-landscape + $gutter-landscape;
  }

  @include below($breakpoint-portrait) {
    width: $grid-tile-portrait + $gutter-portrait;
  }

  @include below($breakpoint-mobile) {
    width: $gutter-mobile;
  }
}

@mixin layout-header-1 {
  padding-bottom: 20px;
}

@mixin layout-header-2 {
  padding-bottom: 30px;
}

@mixin layout-header-3 {
  padding-bottom: 20px;
}

@mixin layout-header-4 {
  padding-bottom: 10px;
}

@mixin layout-header-5 {
  padding-bottom: 0;
}

@mixin layout-paragraph {
  padding-bottom: 20px;
}

// (Main) text block adjacent to colored block
@mixin text-block {
  position: absolute;
  width: 480px;

  @include below($breakpoint-maxlogowidth) {
    width: 360px;
  }

  @include below($breakpoint-mobile) {
    width: calc(100% - #{$block-size-mobile});
  }
}

@if $with-base-css {
  .content-column {
    @include content-column;
  }

  .content-column {
    @include content-wrapper;
  }
}
