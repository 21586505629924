//
// Icon Button
//

$icon-button-colors: (
    "pink" $color-main-pink $color-main-black false,
    "green" $color-main-green $color-main-black false,
    "white" $color-main-white $color-main-black false,
    "black" $color-main-black $color-main-white true,
    "trans" rgba($color-main-black, 0.4) $color-main-white false,
);

@mixin icon-button($height: $button-size, $mobile-height: $button-size-mobile, $bg-color: $color-main-green, $fg-color: $color-main-black,
                   $icon: false) {
  @include icon-variations($height, $bg-color:$bg-color, $fg-color:$fg-color, $icon-tuple:$icon);
  cursor: pointer;
  display: block;
  font-size: 0;

  @each $variation in $icon-button-colors {
    $name: nth($variation, 1);
    $v-bg-color: nth($variation, 2);
    $v-fg-color: nth($variation, 3);

    &.v-#{$name} {
      @include icon-color-variation($v-bg-color, $v-fg-color);
    }
  }

  &:hover, &.on-hover,
  &:active, &.on-click, &.on-active {
    @include icon-color-variation($color-main-black, $color-main-white);

    @each $variation in $icon-button-colors {
      $name: nth($variation, 1);
      $v-bg-color: nth($variation, 2);
      $v-fg-color: nth($variation, 3);
      $inverse: nth($variation, 4);

      @if $inverse {
        &.v-#{$name} {
          @include icon-color-variation($v-fg-color, $v-bg-color);
        }
      }
    }
  }

  &.v-mobile {
    @include icon-variations($mobile-height, $bg-color: $bg-color, $icon-tuple: $icon);

    &:active, &.on-click {
      @include icon-color-variation($color-main-black, $color-main-white);
    }
  }

  &::after {
    @content;
  }
}

@if $with-base-css {
  .c-icon-button {
    @include icon-button;
  }

  .c-share-button {
    @include icon-button($height: 40px, $bg-color: $color-main-grey, $fg-color: $color-main-black);
  }

  .v-facebook::after {
    padding-right: 13px;
  }
}

//
// Text Icon Button
//

$text-button-colors: (
    "green" $color-main-green $color-main-black false,
    "black" $color-main-black $color-main-white true,
    "grey" $color-main-grey $color-main-black false,
    "yellow" $color-main-black $color-main-yellow false,
    "iyellow" $color-main-yellow $color-main-black false,
);

/* A size variation of a configured c-text-icon-button-old */
@mixin text-icon-button-size-variation($size, $icon-ratio: 100, $location:'after') {
  height: $size;
  line-height: $size;
  @if $location == 'after' {
    padding-left: 25px;
    padding-right: 0;
  } @else {
    padding-left: 0;
    padding-right: 25px;
  }

  @include icon-size-variation($icon-data-triangle-right, $size, $ratio: $icon-ratio, $location:$location) {
    @if $location == 'after' {
      margin-right: 10px;
    } @else {
      margin-left: 10px;
    }
  }

  @include below($breakpoint-landscape) {
    height: $size;
    line-height: $size;
  }

  @include below($breakpoint-portrait) {
    height: $size;
    line-height: $size;
    @if $location == 'after' {
      padding-right: 7px;
    } @else {
      padding-left: 7px;
    }

    &::#{$location} {
      @if $location == 'after' {
        margin-right: 7px;
      } @else {
        margin-left: 7px;
      }
    }
  }

  @include below($breakpoint-mobile) {
    height: $size;
    line-height: $size;
  }
}

@mixin text-icon-button($bg-color:$color-main-green, $fg-color:$color-main-black, $hover-bg-color:$color-main-black,
    $hover-fg-color:$color-main-white, $icon:$icon-data-triangle-right, $size: $button-size, $location:'after') {
  @include typography-button;
  @include text-icon-button-size-variation($size, $location:$location);
  @include base-icon($icon, $size, transparent, $location:$location);
  background-color: $bg-color;
  color: $fg-color;
  display: inline-block;
  vertical-align: bottom;

  @each $variation in $text-button-colors {
    $name: nth($variation, 1);
    $bg-color: nth($variation, 2);
    $fg-color: nth($variation, 3);

    &.v-#{$name} {
      background-color: $bg-color;
      color: $fg-color;

      &::before, &::after {
        background-color: transparent;
        color: $fg-color;
      }
    }
  }

  &:hover, &.on-hover,
  &:active, &.on-click {
    @include icon-color-variation($fg-color:$hover-fg-color);
    background-color: $hover-bg-color;
    color: $hover-fg-color;
    cursor: pointer;
    text-decoration: none;

    @each $variation in $icon-button-colors {
      $name: nth($variation, 1);
      $v-bg-color: nth($variation, 2);
      $v-fg-color: nth($variation, 3);
      $inverse: nth($variation, 4);

      @if $inverse {
        &.v-#{$name} {
          background-color: $hover-fg-color;
          color: $hover-bg-color;
          @include icon-color-variation($v-fg-color, $v-bg-color);
        }
      }
    }

    &:before {

      color: #fff;
    }
  }
}

@if $with-base-css {
  .c-text-icon-button {
    @include text-icon-button;

    &.v-scroll-up-button {
      @include base-icon($icon-data-triangle-up, $button-size, transparent);
      background-color: $color-main-yellow;
    }

    &.v-footer-button {
      @include text-icon-button-size-variation($button-size, $location:'before');
      @include base-icon($icon-data-triangle-right, $button-size, transparent, $fg-color:$color-main-green, $location:'before');
      background-color: $color-main-black;
      color: $color-main-white;
      &::after {
        display: none;
      }
    }
  }
}

@mixin text-button($bg-color:$color-main-green, $fg-color:$color-main-black, $hover-bg-color:$color-main-black,
    $hover-fg-color:$color-main-white) {
  $size: $button-size;
  @include typography-button;
  background-color: $bg-color;
  color: $fg-color;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: bottom;

  &:hover, &.on-hover,
  &:active, &.on-click {
    background-color: $hover-bg-color;
    color: $hover-fg-color;
    cursor: pointer;
    outline: none;
  }
}

@if $with-base-css {
  .c-text-button {
    @include text-button;
  }
}

//
// Experimental button
//

/* A size variation of a configured c-text-icon-button */
@mixin text-icon-button-experiment-2-size-variation($size, $horizontal-padding, $border, $offset-factor) {
  $offset: -$size * $offset-factor - $size * $border * 0.5;
  height: $size;
  line-height: $size + $offset * 1.75;
  margin-left: - $offset;
  padding-left: $horizontal-padding;

  &::before {
    border: $size * $border solid $color-main-black;
    left: $offset;
    height: calc(100% + #{$size * $border * 0.5});
    top: $offset;
    width: calc(100% + #{$size * $border * 0.5});
  }

  @include icon-size-variation("random string 233" $icon-arrow-right 60, $size, $mode: "padding") {
    line-height: 1;
    margin-left: $horizontal-padding;
    top: -$size * $offset-factor - $size * $border * 0.25;
    left: -$size * $offset-factor - $size * $border * 0.25;
  }
}

$icon-button-experiment-2-colors: (
    "pink" $color-main-pink $color-main-black,
    "grey" $color-main-grey $color-main-black,
    "blue" $color-main-blue $color-main-black,
    "yellow" $color-main-yellow $color-main-black,
    "green" $color-main-green $color-main-black,
);

@mixin icon-button-experiment-2($size, $border-factor, $offset-factor, $padding-h) {
  @include typography-button;
  @include text-icon-button-experiment-2-size-variation($size, $padding-h, $border-factor, $offset-factor);
  color: transparent;
  display: inline-block;
  position: relative;
  vertical-align: top;
  z-index: 2;

  @include base-icon("random string" $icon-arrow-right 60, $size, transparent) {
    line-height: 1;
    position: relative;
    vertical-align: top;
  }

  &::before {
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
    text-align: left;
    z-index: -1;
  }

  @each $variation in $icon-button-experiment-2-colors {
    $name: nth($variation, 1);
    $bg-color: nth($variation, 2);
    $fg-color: nth($variation, 3);

    &.v-#{$name} {
      background-color: $bg-color;
      color: $fg-color;
    }
  }

  &:hover, &.on-hover {
    @include text-icon-button-experiment-2-size-variation($size, $padding-h, $border-factor, 0);
    $offset: -$size * $offset-factor - $size * $border-factor * 0.5;
    cursor: pointer;
    margin-left: - $offset;
  }
}

@if $with-base-css {
  .c-icon-button-experiment-2 {
    $border-factor: 0.12;
    $offset-factor: 0.15;
    $size: 1;
    @include icon-button-experiment-2($size, $border-factor, $offset-factor, 0);
    @include icon-variations($size, false, $ratio: 75);
  }

  .c-text-icon-button-experiment-2 {
    $padding-h: 25px;
    $border-factor: 0.12;
    $offset-factor: 0.15;
    $size: 1;
    @include icon-button-experiment-2($size, $border-factor, $offset-factor, $padding-h);

    &.v-mobile {
      $size: 1;
      $padding-h: 20px;
      @include text-icon-button-experiment-2-size-variation($size, $padding-h, $border-factor, $offset-factor);

      &:hover, &.on-hover {
        @include text-icon-button-experiment-2-size-variation($size, $padding-h, $border-factor, 0);
        $offset: -$size * $offset-factor - $size * $border-factor * 0.5;
        cursor: pointer;
        margin-left: - $offset;
      }
    }
  }
}
