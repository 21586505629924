
.page-share {
  margin-top: 20px;
  margin-bottom: 35px;

  &.v-wide {
    margin-top: -20px;
    margin-bottom: 80px;

    @include below($breakpoint-mobile-overview-blocks) {
      margin-top: 0;
      margin-bottom: 40px;
      text-align: left;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &:not(.v-no-line) {
    &::before {
      content: '';
      display: block;
      border-top: 3px solid $color-main-grey;
      padding-top: 40px;
      clear: both;
    }
  }

  .share {
    @include typography-paragraph;
    line-height: 40px;
    display: inline-block;
    margin-right: 20px;
 
  }

  .page-share-wrapper {
    display: inline-block;

    .c-share-button {
      font-size: 10px;
      overflow: hidden; 
      position:relative

    }

    li {
      display: inline-block;
      vertical-align: middle;

    }

    span {
      position: absolute;
      bottom: -20px;
   
    }

    .pinterest {
      display: none; // to hide the share text and pinterest
    }
  }

  .simple-overview-page & {
    &:not(.v-no-line) {
      &::before {
        border-top-color: $color-and-yet-another-grey;
      }
    }

    .c-share-button {
      
      &::after {
        background: $color-and-yet-another-grey;
      }

      &:hover,
      &.on-hover,
      &:active,
      &.on-click,
      &.on-active {
        &::after {
          background: $color-main-black;
        }
      }
    }
  }

  &:not(.v-wide) {
    @include grid-padding;
  }
}

.section-intermezzo + .page-share {
  &::before {
    border-top: none;
  }
}
