@keyframes hide {
  0% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.0;
    transform: translateY(100%);
  }
}

.cookie-panel {
  z-index: 5000;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease, transform 0.4s ease;
  width: 100%;

  &:not([data-state="active"]):not([data-state="deactivating-active"]) {
    .cookie-panel__state-active {
      display: none;
    }
  }

  &:not([data-state="configurator"]):not([data-state="deactivating-configurator"]) {
    .cookie-panel__state-configurator {
      display: none;
    }
  }

  &:not(.cookie-panel--activated) {
    transform: translateY(100%);
  }

  &[data-state="deactivating-active"],
  &[data-state="deactivating-configurator"] {
    animation: hide 0.4s ease;
  }

  .c-text-icon-button {
    margin: 5px 0;

    @include below($breakpoint-mobile) {
      padding-left: 20px;
      padding-right: 20px;

      &:after {
        display: none;
      }
    }
    
    &.v-allow-cookies {
      @include text-icon-button;
      @include below($breakpoint-mobile) {
        padding-left: 20px;
        font: 500 14px / 50px $font-campton-book;
        text-align: left;
      }

      @include below($breakpoint-extrasmall) {
        padding-right: 20px;
      }

      &:after {
        @include below($breakpoint-mobile) {
          width: 30px;
        }
        @include below($breakpoint-extrasmall) {
          display:none;
        }
      }
    }
  }

  .v-close {
    @include icon-color-variation($color-main-grey, $color-main-black);

    &:hover, &:active {
      @include icon-color-variation($color-main-black, $color-main-white);
    }
  }

  @include above($breakpoint-medium) {
    max-width: 560px;
  }

  &.cookie-panel--activated ~ .newsletter-popup {
    transform: translateY(100%);
  }
}

.cookie-panel__state-active {
  background: $color-main-grey;
  color: $color-main-black;
  padding-bottom: 1px;

  .cookie-panel__fields {
    display: none;
  }

  .c-link, a {
    color: $color-main-black;
    cursor: pointer;
    text-decoration: underline;
  }
}

.cookie-panel__state-configurator {
  background: $color-main-grey;
  color: $color-main-black;
  padding-bottom: 1px;
}

.cookie-panel__state-error {
  background: $color-main-red;
  padding-bottom: 1px;
}

.cookie-panel__state-success {
  background: $color-main-black;
  color: $color-main-white;
  padding-bottom: 1px;
}

.cookie-panel__active-content {
  font: 500 14px / 22px $font-campton-book;
  margin-bottom: 20px;
  position: relative;

  @include above($breakpoint-medium) {
    font: 500 16px / 24px $font-campton-book;
    margin-bottom: 50px;
  }
}

.cookie-panel__error-content {
  margin-bottom: 20px;
  position: relative;

  @include above($breakpoint-medium) {
    margin-bottom: 50px;
  }
}

.cookie-panel__success-content {
  margin-bottom: 20px;
  position: relative;

  @include above($breakpoint-medium) {
    margin-bottom: 50px;
  }
}

.cookie-panel__button-row {
  display: flex;
  justify-content: flex-end;
}

.cookie-panel__fields {
  margin: 0 70px 10px 20px;

  @include below($breakpoint-mobile) {
    margin: 0 20px 10px 20px;
  }

  input {
    margin: 0 5px;

    @include below($breakpoint-mobile) {
      position: absolute;
      left: -30px;
      top: 0;
    }
  }

  label {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0;
    position: relative;

    @include below($breakpoint-mobile) {
      margin: 20px 0 20px 30px;
    }
  }

  @include above($breakpoint-medium) {
    margin: 20px 30px 10px 30px;

    label {
      flex-wrap: nowrap;
    }
  }

  .option-title {
    font: 400 16px / 18px $font-campton-book;
    font-weight: bold;
    display: inline-block;
    min-width: 160px;

    @include below($breakpoint-small) {
      font: 400 14px / 18px $font-campton-medium;
    }
  }

  .option-context {
    font: 500 14px / 22px $font-campton-book;
    display: inline-block;
    margin: 0 10px;

    @include below($breakpoint-mobile) {
      margin-left: 0;
    }
  }
}

.cookie-panel__title {
  font: 500 18px / 25px $font-campton-medium;
  margin: 0 70px 10px 20px;

  @include below($breakpoint-small) {
      font: 400 16px / 17px $font-campton-medium;
  }
  @include above($breakpoint-medium) {
    font: 500 26px / 30px $font-campton-book;
    margin: 20px 30px 10px 30px;
  }
}

.cookie-panel__information {
  margin: 0 20px 20px 20px;

  @include below($breakpoint-medium) {
    font: 500 14px / 22px $font-campton-book;
  }

  @include above($breakpoint-medium) {
    margin: 0 30px 10px 30px;
  }
}

.cookie-panel__cta-row {
  margin: 20px;
  display: flex;

  .c-link, a, .c-text-icon-button {
    &:nth-child(n + 2) {
      margin-left: 30px;
      @include below($breakpoint-medium) {
        margin-left: 20px;
      }
    }
  }

  @include above($breakpoint-medium) {
    margin: 30px 30px 30px 30px;
  }
}
