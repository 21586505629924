@mixin typography-1 {
  color: $color-secondary-grey;
  font-family: $corbel;
  font-size: 19px;
  line-height: 125%;
}

// Redesign

@mixin typography-default($line-height:24px 24px) {
  //hyphens: none;
  font-family: $font-campton-book;
  font-size: 16px;
  line-height: nth($line-height, 1);
  text-decoration: none;
  text-transform: none;
}

@mixin typography-default-bold($line-height:24px 24px) {
  //hyphens: none;
  font-family: $font-campton-medium;
  font-size: 16px;
  line-height: nth($line-height, 1);
  text-decoration: none;
  text-transform: none;
}

@mixin typography-small {
  @include typography-default;
  font-size: 16px;
  line-height: 23px;
}

@mixin typography-science-museum {
  hyphens: none;
  font-family: $font-campton-book;
  font-size: 50px;
  line-height: 52px;
  text-transform: uppercase;
}

@mixin typography-header-1 {
  hyphens: none;
  font-family: $font-campton-book;
  font-size: 70px;
  line-height: 72px;
  word-break: normal;

  @include below(530px) {
    font-size: 40px;
    line-height: 44px;
  }
}

@mixin typography-header-2 {
  hyphens: none;
  font-family: $font-campton-book;
  font-size: 50px;
  line-height: 52px;

  @include below(530px) {
    font-size: 32px;
    line-height: 36px;
  }
}

// For multiline titles the breakpoint to the smaller font is increased
@mixin typography-header-2-multiline {
  hyphens: none;
  font-family: $font-campton-book;
  font-size: 50px;
  line-height: 52px;

  @include below(760px) {
    font-size: 32px;
    line-height: 36px;
  }
}

@mixin typography-header-3($line-height: 44px 30px) {
  hyphens: none;
  font-family: $font-campton-book;
  font-size: 40px;
  line-height: nth($line-height, 1);

  @include below(530px) {
    font-size: 26px;
    line-height: nth($line-height, 2);
  }
}

@mixin typography-header-4($line-height:30px 25px) {
  hyphens: none;
  font-family: $font-campton-book;
  font-size: 26px;
  line-height: nth($line-height, 1);

  @include below(530px) {
    font-family: $font-campton-medium;
    font-size: 18px;
    line-height: nth($line-height, 2);
  }
}

// For multiline titles the breakpoint to the smaller font is increased
@mixin typography-header-4-multiline($line-height:30px 25px) {
  hyphens: none;
  font-family: $font-campton-book;
  font-size: 26px;
  line-height: nth($line-height, 1);

  @include below(760px) {
    font-family: $font-campton-medium;
    font-size: 18px;
    line-height: nth($line-height, 2);
  }
}

@mixin typography-header-5 {
  hyphens: none;
  font-family: $font-campton-medium;
  font-size: 18px;
  line-height: 25px;
}

@mixin typography-intro {
  //hyphens: none;
  font-family: $font-campton-book;
  font-size: 22px;
  line-height: 32px;

  @include below(530px) {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin typography-paragraph {
  @include typography-default;
}

@mixin typography-button {
  font-family: $font-campton-medium;
  font-size: 16px;
  line-height: 50px;
  text-decoration: none;
  text-transform: none;
}

@mixin typography-label {
  font-family: $font-campton-medium;
  font-size: 16px;
  line-height: 50px;
  text-decoration: none;
}

@mixin typography-caption {
  @include typography-paragraph;
  font-family: $font-campton-medium;
}

@if $with-base-css {
  .c-typography-default {
    @include typography-default;
  }

  .c-typography-header-1 {
    @include typography-header-1;
  }

  .c-typography-header-2 {
    @include typography-header-2;
  }

  .c-typography-header-3 {
    @include typography-header-3;
  }

  .c-typography-header-4 {
    @include typography-header-4;
  }

  .c-typography-header-5 {
    @include typography-header-5;
  }

  .c-typography-intro {
    @include typography-intro;
  }

  .c-typography-paragraph {
    @include typography-paragraph;
  }
}
