.page-section-related {
  @include clearfix;
  padding-top: $grid-tile;
  padding-bottom: 100px;
  position: relative;
  z-index: $layer-content; //above header image fixed
  background-color: $color-main-grey;

  .extended-related-pages, .related-pages {
    @include max-width;
    @include grid-padding;
  }

  // related pages with buttons only
  > h3 {
    @include max-width;
    @include grid-padding;
    @include typography-header-3;
    margin-bottom: 20px;
  }

  .related-pages {
    li {
      display: inline-block;
      margin: 0 20px 20px 0;

      a {
        @include coloured-label($link: true);
        font-size: 16px;
      }
    }
  }

  // related pages with block and images
  .extended-related-pages {
    li {
      width: 100%;
      max-width: 460px;
      display: inline-block;
      margin-bottom: 20px;

      @include desktop {
        width: 49%;
        max-width: 380px;
        padding-right: 20px;
      }
    }
  }

  .extended-related-page-block, .extended-related-page-freestanding {
    @include clearfix;
    @include display(flex);
    position: relative;

    img {
      width: 100px;
      height: 100px;
    }

    span,
    p {
      @include typography-caption;
      @include flex(1);
      height: 100px;
      padding: 15px 40px 15px 15px;
      color: $color-main-black;
      background-color: $color-main-white;

      &:hover {
        background-color: $color-main-green;
      }
    }

    .extended-related-page-block__label,
    label {
      $height: 30px;
      @include coloured-label($height: $height, $padding-horizontal: 20px, $mode: "px");
      display: none;
      position: absolute;
      top: -$height;
      z-index: $layer-content;
    }

    .page-section-related__hidden-label {
      display: none;
    }
  }
}