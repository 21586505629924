
.page-upsell {
  @include clearfix;
  padding: 35px 0;
  position: relative;
  z-index: $layer-content; //above header image fixed
  body & {
    background-color: $color-main-grey;
  }

  // related pages with buttons only
  h3 {
    @include typography-header-4;
    margin-bottom: 0;
  }

  @include above($breakpoint-portrait) {
    h3 {
      max-width: calc(100% - 220px);
      float: left;
      line-height: 50px;
    }

    .upsell-button {
      float: left;
      margin-left: 50px;
    }
  }

  @include below($breakpoint-mobile-overview-blocks) {
    padding: 20px;

    .upsell-button {
      margin-top: 20px;
    }
  }
}

.upsell-button {
  @include text-icon-button;
}
