$target-debug: true;

// Settings

$target-has-hover: true !default; // true by default

$target-tablet-landscape-width: 1024px;
$target-tablet-portrait-width: 768px;
$target-mobile-portrait-width: 320px;

$target-tablet-landscape-breakpoint: $target-tablet-landscape-width;
$target-tablet-portrait-breakpoint: (($target-tablet-landscape-width + $target-tablet-portrait-width)/2);
$target-mobile-breakpoint: (($target-tablet-portrait-width + $target-mobile-portrait-width)/2);

// Placeholders & mixins

@mixin desktop {
  @media (min-width: $target-tablet-landscape-breakpoint + 1px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (width: $target-tablet-landscape-breakpoint) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (max-width: $target-tablet-landscape-width - 1px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $target-mobile-breakpoint) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-width: 999px) and (max-height: 529px) {
    @content;
  }
}
