//
// Hover-mixin
// If target platform has hover-states, it will render them with :hover
// Otherwise it will use :active as the "hover"-state
//
//@mixin hover {
//  @if $target-has-hover {
//    &:hover, .on-hover {
//      @content;
//    }
//  } @else {
//    &:active, .on-active {
//      @content;
//    }
//  }
//}
//shouldn't we use this?

@mixin hover {
  body.no-touch &:hover {
    @content;
  }

  //body.touch &:active {
  //  @content;
  //}
}

@mixin checkbox() {
  .e-face {
    background-color: $color-main-white;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    left: 0;
    position: absolute;
    vertical-align: middle;
    width: 20px;
  }

  input {
    position: relative;
    height: 20px;
    width: 20px;
    z-index: 1000;
  }

  input:checked + .e-face::after {
    @include themed-signal-color;
    content: $icon-check;
    display: block;
    font-family: icons;
    position: absolute;
    left: -1px;
    font-size: 22px;
    top: -3px;
  }
}
