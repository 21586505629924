$slide-width: 277px;

@mixin small-slide--icon {
  opacity: 0;
}

@mixin background-image() {
  height: 100%;
  left: 0;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  width: 100%;
}

@mixin big-exhibition($num-slides) {
  background: transparent !important;
  position: absolute;
  top: 0;
  left: auto;
  right: ($num-slides - 1) * $slide-width;
  height: 100%;
  z-index: 7;

  .exhibitions-slide__content-wrapper {
    &::before {
      right: calc(100% - #{$slide-width});
      margin-right: calc(-100% + #{$slide-width});
    }
  }

  .exhibitions-slide__text {
    top: calc(50% - 28px);
    padding: 0 40px;
    position: relative;

    @include below($breakpoint-landscape) {
      top: calc(50% - 14px);
    }

    h2 {
      font: 70px / 1 $font-campton-medium;
      margin-bottom: 20px;

      @include below($breakpoint-landscape) {
        font: 52px / 1 $font-campton-medium;
        margin-bottom: 15px;
      }
    }

    p {
      font: 20px / 24px $font-campton-medium;
      opacity: 1.0;

      &::after {
        font-size: 14px;
      }
    }
  }

  .exhibitions-slide__visual-big {
    left: auto;
  }

  .exhibitions-slide__visual-big {
    opacity: 1;
  }

  .exhibitions-slide__visual-small {
    visibility: hidden;
  }

  &.show-video {
    .exhibitions-slide__visual-big {
      opacity: 0.0;
    }
  }
}

@mixin small-exhibition($swap, $slide-num, $num-slides) {
  position: absolute;
  top: 0;
  left: auto;
  right: $slide-width * $num-slides - $slide-width * (($slide-num + $num-slides - $swap) % $num-slides + 1);
  height: 100%;
  transition: right 600ms ease 600ms, width 600ms ease;
  @if ($swap - $slide-num + $num-slides) % $num-slides == 2 {
    z-index: 6;
  } @else {
    z-index: 5;
  }

  .exhibitions-slide__content-wrapper {
    &::before {
      width: $slide-width;
      transition: background-color 600ms ease, margin-right 600ms ease, right 600ms ease, width 600ms ease;
    }
  }

  .exhibitions-slide__visual-big {
    left: auto;
    right: 0;
    transition: margin-right 600ms ease, right 600ms ease, opacity 200ms ease, width 600ms ease;
    width: $slide-width;
  }

  .exhibitions-slide__text {
    top: calc(50% - 23px);
    @if ($swap - $slide-num + $num-slides) % $num-slides == 1 {
      padding: 0 40px 0 20px;
    } @else {
      padding: 0 20px;
    }

    h2 {
      font: 32px / 1 $font-campton-medium;
      margin-bottom: 10px;
    }

    p {
      font: 16px / 20px $font-campton-medium;
    }
  }

  .exhibitions-slide__visual-small {
    left: auto;
    right: 0;
    visibility: hidden;
  }

  &.is-open {
    width: $slide-width * 2;
    transition: right 600ms ease, width 600ms ease;
    z-index: 10;

    .exhibitions-slide__content-wrapper {
      &::before {
        width: $slide-width * 2;
      }
    }

    .exhibitions-slide__visual-small {
      visibility: hidden;
    }

    .exhibitions-slide__visual-big {
      right: 0;
      opacity: 1;
      transition: right 600ms ease, width 600ms ease;
      visibility: visible;
      width: $slide-width * 2;
    }
  }
}


.home-page .slide-content.exhibitions-slide {
  background: black;

  @include above($breakpoint-portrait) {
    @for $i from 2 through 3 {
      @for $j from 1 through $i {
        &.v-#{$i}-#{$j} {
          .exhibitions-slide__video-wrapper {
            width: calc(100% - #{$slide-width * ($i - 1)});
          }

          .exhibitions-slide__video[data-for="#{$j - 1}"] {
            display: block;
          }

          @for $k from 1 through $i {
            $selector: '.exhibitions-slide__exhibition:nth-child(#{$k}):nth-last-child(#{1 + $i - $k})';
            #{$selector} {
              @if $k == $j {
                @include big-exhibition($i);
              } @else {
                @include small-exhibition($j, $k, $i);
              }
            }

            &.extra-hover-space {
              @if $k == $j % $i + 1 {
                #{$selector} {
                  right: $slide-width * 2;
                  transition: right 600ms ease;
                }
              }
            }

            &.faded {
              @if $k == $j {
                #{$selector} {
                  z-index: 7;
                }
              }
            }
          }
        }
      }
    }

    &.faded {
      .exhibitions-slide__text {
        opacity: 0;
      }
    }

    &.show-video {
      .exhibitions-slide__video-wrapper {
        visibility: visible;
      }
    }
  }

  .e-extra-logo-group {
    z-index: 10;
  }

  @include below($breakpoint-portrait) {
    height: auto;
  }
}

.exhibitions-slide__tickets.c-text-button {
  width: 150px;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 0;
  right: $slide-width - 150px;
  z-index: 20;

  @include below($breakpoint-mobile) {
    right: 50px;
  }
}

.exhibitions-slide__wrapper {
  height: 100%;
  position: relative;

  @include below($breakpoint-portrait) {
    height: auto;
  }
}

.exhibitions-slide__video-wrapper {
  position: absolute;
  visibility: hidden;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.exhibitions-slide__content-wrapper {
  position: relative;
  transition: right 600ms ease 600ms, width 600ms ease;
  z-index: 1;

  @include below($breakpoint-portrait) {
    height: 0;
    padding-bottom: 107%;
  }

  @include above($breakpoint-portrait) {
    height: 100%;
  }

  &::before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: rgba(black, .2);
    transition: background-color 600ms ease, right 600ms ease 600ms, margin-right 600ms ease 600ms, width 600ms ease;
    z-index: 4;
  }

  &:hover {
    &::before {
      background: rgba(black, .05);
    }
  }
}

.exhibitions-slide__exhibition {
  display: block;
  overflow: hidden;
  transition: right 600ms ease 600ms, width 600ms ease;

  &:hover {
    .exhibitions-slide__text {
      transform: translateY(-10px);
    }
  }

  @include below($breakpoint-portrait) {
    height: 0;
    position: relative;
    padding-bottom: 107%;
  }
}

.exhibitions-slide__visual-big {
  @include background-image;
  left: auto;
  margin-right: 0;
  right: 0;
}

.exhibitions-slide__video {
  display: none;
  z-index: 3;
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include above($breakpoint-portrait) {
    font-family: 'object-fit: cover;';
  }
}

.exhibitions-slide__visual-big {
  transition: right 600ms ease 600ms, margin-right 600ms ease 600ms, opacity 200ms ease, width 600ms ease;
  z-index: 2;

  @include below($breakpoint-portrait) {
    display: none;
  }
}

.exhibitions-slide__visual-small {
  @include background-image;
  z-index: 1;

  @include below($breakpoint-portrait) {
    display: none;
  }
}

.exhibitions-slide__visual-mobile {
  @include background-image;
  z-index: 1;

  @include above($breakpoint-portrait) {
    display: none;
  }
}

.exhibitions-slide__text {
  color: white;
  position: absolute;
  left: 0;
  padding: 0 20px;
  top: calc(50% - 30px);
  transition: opacity 400ms ease, transform 400ms ease;
  z-index: 4;
  width: 100%;
  &:after {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: -58vh;
    left: 0;
    width: 100%;
    height: 100vh;

    @include below($breakpoint-medium) {
      top: -48vh;
    }
  }

  h2 {
    font: 40px / 1 $font-campton-medium;
    margin-bottom: 15px;
  }

  p {
    font: 16px / 20px $font-campton-medium;

    @include add-icon($icon-full-arrow-right) {
      font-size: 11px;
      margin-top: 20px;
      padding-left: 2px;
    }
  }
}
