.c-main-logo {
  width: $logosize;
  position: absolute;
  left: 0;
  top: 0;
  z-index: $layer-header;

  img {
    width: 100%;
    height: auto;
    max-width: $logomaxwidth;
    max-height: $logomaxwidth;
    min-width: 100px;
    min-height: 100px;
    display: block;
    
    @include below($breakpoint-full-logo) { 
      width: 80%;
      min-width: 82px;
    }
  }

  &.v-plain {
    position: static;
  }

  //@include below($breakpoint-landscape) {
  //  $size: $grid-tile-landscape * 3;
  //  height: auto;
  //  width: $size;
  //}
  //
  //@include below($breakpoint-portrait) {
  //  $size: $grid-tile-portrait * 3;
  //}
  //
  //@include below($breakpoint-mobile) {
  //  $size: $grid-tile-mobile * 3;
  //}

}