@mixin input-field($border:true) {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  vertical-align: bottom;
  @if $border {
    border: 2px solid $color-main-grey;
    border-right: none;
  } @else {
    border: none;
  }
  background-color: $color-main-white;
  @include typography-default();
}