.page-aside {
  $padding-horizontal: 20px;
  margin-bottom: 25px;
  padding-left: $grid-tile;
  padding-right: $grid-tile;
  margin-top: 5px;
  width: 320px;
  background-color: $color-main-white;
  float: right;
  position: relative;
  z-index: 10000;

  h3 {
    @include typography-header-5;
    padding: 15px $padding-horizontal 0;
    margin-bottom: 5px;
  }

  p {
    @include typography-small;
    padding: 0 $padding-horizontal;
    margin-bottom: 15px;
  }

  ul {
    padding: 0 $padding-horizontal;

    li {
      position: relative;
      display: block;
      padding-left: 17px; // outlined with contact aside block
    }

    li::before {
      font-size: 9px;
      position: absolute;
      padding-top: 6px;
    }
  }

  a {
    @include typography-small;
    color: $color-main-black;

    &:hover, &.on-hover {
      text-decoration: underline;
    }
  }

  .section-contact {
    background-color: $color-main-green;
    padding-bottom: 15px;
    width: 100%;

    .section-contact__title {
      hyphens: none;
      font-family: $font-campton-book, Medium, sans-serif;
      font-size: 20px;
      line-height: 28px;
      padding: 15px 20px 0;
      margin-bottom: 5px;
      font-weight: 600; 
    }

    .phone {
      @include add-icon($icon-phone, $location: 'before') {
        position: relative;
        top: 1px;
      }
    }

    .email {
      @include add-icon($icon-mail, $location: 'before') {
        position: relative;
        top: 1px;
      }
    }

    > :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .section-related {
    background-color: $color-main-grey;
    padding-bottom: 20px;

    .section-related__title {
      hyphens: none;
      @include typography-header-4;
      font-size: 18px;
      line-height: 25px;
      padding: 15px 20px 0;
      margin-bottom: 5px;
      font-weight: 600;
    }

    > :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include below(800px) {
    padding-left: 20px;
    width: 280px;
  }

  @include below($breakpoint-mobile) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .section-related {
      display: none;
    }
  }
}

.page-section.max-w-div {
  z-index: 999;

  &::after {
    clear: none;
    display: none;
  }
}