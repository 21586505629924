@mixin figure {
  @include grid-padding($mode: "margin");
  position: relative;
  font-size: 0; // for correct positioning of the figcaptions
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;
  }
}

.page-section-content {
  @include max-width;

  > h2 {
    padding-top: 5px;
  }

  > figure {
    @include figure;
  }

  > figure > figcaption {
    @include figcaption-label;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.page-section {
  &--clear-after {
    @include clearfix;
  }

  //body:not(.simple-overview-page):not(.theme-overview-page) &:not(.experiment-steps-section):not(.experiment-conclusion):not(.v-grey) {
  //  background-color: $color-main-white;
  //}

  margin-left: 0;
  margin-right: auto;
  padding: 0;
  position: relative;
  z-index: $layer-content;

  &:last-child {
    padding-bottom: 100px;
  }

  .page-aside {
    position: relative;
    float: right;

    z-index: $layer-content-widget;
  }

  .page-title-label {
    line-height: 50px;
    padding-left: 0;
    position: absolute;
    top: -50px;
    left: 0;
    z-index: $layer-content;

    span,
    a {
      font-size: 16px;
      body.no-header-visual & {
        @include label($link: true, $uppercase: true);
        background: $color-main-grey;
      }
      body:not(.no-header-visual) & {
        @include coloured-label($link: true, $uppercase: true);
      }
      line-height: 54px;
    }
  }

  .page-title {
    @include content-section-block;
    @include typography-header-1;
    float: left;
    padding-top: $grid-tile;
    width: 100%;
    z-index: $layer-content + 1;
    padding-bottom: 10px;
  }

  .subtitle {
    @include content-section-block;
    @include typography-paragraph;
    color: $color-tertiary-grey;
  }

  .page-intro {
    clear: left;
    padding-top: 10px; // We don't know if there is a subtitle or not.
    @include content-section-block;
    @include typography-intro;
    margin-bottom: 25px;
  }

  // Typical plain elements in a section
  > h2,
  article.form h2 {
    @include content-section-block;
    @include typography-header-3;
    padding-bottom: 5px;
  }

  > h3 {
    @include grid-padding;
    @include typography-header-4;
    line-height: 32px;

    @mixin below($breakpoint-mobile) {
      font-size: 22px;
      line-height: 28px;
    }

  }

  > h4 {
    @include grid-padding;
    @include typography-header-5;
  }

  > p {
    margin-bottom: 20px;
  }

  > p:last-child {
    margin-bottom: 0;
    padding-bottom: 20px
  }

  /* Hopefully we can slowly rewrite these things with BEM-style */
  > p:not(.share):not(.page-section__intro) {
    @include grid-padding;
    @include rich-text;
    @include typography-paragraph;

    strong {
      @include typography-header-5;
      padding-bottom: 10px;
    }

    & + p {
      margin-top: -10px;
    }

    & + ul {
      margin-top: -25px;
    }
  }

  &.extra-z-index {
    z-index: $layer-content + 901;
  }

  @include below($breakpoint-mobile) {
    .page-title {
      padding-top: $gutter-mobile;
    }
  }

  $color-variations: 'green' $color-main-green,
  'grey' $color-main-grey;
  @each $color in $color-variations {
    $name: nth($color, 1);
    $value: nth($color, 2);

    &.v-#{$name}, &.v-#{$name} h2 {
      background-color: $value;
      max-width: none !important;
      width: 100% !important;
      padding-top: 15px;

      p {
        @include max-width;
      }
    }
  }

  .figure-center-landscape, .figure-left-portrait, .figure-left-landscape, .figure-right-portrait, .figure-right-landscape {
    @include figure;
  }

  .figure-center-landscape {
    @include grid-padding;
    @include max-width;
    position: relative;
    margin: 0 0 35px 0;

    &.content-video {
      max-width: 600px;
    }

    figcaption {
      @include grid-padding($mode: 'left');
      position: absolute;
      bottom: 0;
      max-width: calc(100% - 40px); // 100% minus mobile padding
    }
  }

  .figure-left-portrait, .figure-left-landscape {
    figcaption {
      @include figcaption-label;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .figure-right-portrait, .figure-right-landscape {
    figcaption {
      @include figcaption-label;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  // this is the breakpoint it starts to get awkward
  @include above(800px) {
    .figure-left-portrait, .figure-left-landscape {
      float: left;
      margin-right: 20px;
    }

    .figure-right-portrait, .figure-right-landscape {
      float: right;
      margin-left: 20px;
    }

    .figure-left-portrait, .figure-right-portrait {
      width: 280px;
    }

    .figure-left-landscape, .figure-right-landscape {
      width: 360px;
    }
  }

  //activity how/what where
  .information-block {
    @include grid-padding;
    @include typography-default;
    @include max-width;
    position: relative;
    padding-top: $margin-small;

    &:last-child {
      margin-bottom: $margin;
    }
    //
    //&::after {
    //  content: "";
    //  position: relative;
    //  display: block;
    //  right: 0;
    //  border-bottom: 2px solid $color-main-grey;
    //  padding-top: $margin-small;
    //}

    .calendar-icon {
      &::before {
        font-family: icons;
        content: $icon-calendar;
        font-size: 40px;
        display: inline-block;
        float: left;
        padding: 10px 10px 10px 0;
      }
    }

    > p .external-link:before {
      top: 3px;
    }

    .e-external-location {
      @include typography-default-bold;
    }

    .e-text {
      @include rich-text;
    }

    .times {
      padding-left: 50px;

      b {
        @include typography-header-5;
        display: block;
      }

      span:not(:empty) {
        display: inline-block;
        padding-bottom: 10px;
      }
    }

    p {
      color: $color-secondary-grey;
      padding-top: 30px;

      &:not(:last-of-type) {
        padding-bottom: 10px;
      }
    }

    .agenda-link {
      padding: $margin-small 0;
      @include arrow-before;
    }

    .reserve-buttons {
      margin-top: 10px;
    }
  }
}

.page-section-first {
  > p {
    padding-top: 10px;
  }
}

.page-section-first, .max-w-div {
  max-width: 1417px; // to align page-aside with overview blocks
  z-index: 102;
}

.page-section-first {
  .page-intro, > h2, > p {
    @include max-width;
  }
}

.page-section-content.v-grey {
  clear: both;
}


.page-section {
  &__title {
    @include content-section-block;
    float: left;
    padding-top: $grid-tile;
    margin-bottom: 10px;
    width: 100%;
    z-index: $layer-content + 1;
  }

  &__header-1 {
    @include typography-header-1;
    max-width: 1417px - 320px;
  }

  &__intro {
    clear: left;
    @include content-section-block;
    @include typography-intro;
    margin-bottom: 35px;

    p:first-child {
      margin-top: -10px;
    }

    p:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}
 
.inner-margined .page-section.page-section-first p.page-title-label {
  line-height: 0;
  padding-left: 0;
  position: absolute;
  top: -50px;
  left: 0;
  z-index: $layer-content;
  padding-right: 80px;
  padding-top: 0; 
  hyphens: none;
  font-family: $font-campton-book, sans-serif;

  > a {
    color: $color-main-black;
    text-transform: uppercase;

    body.no-header-visual & {
      @include label($link: true, $uppercase: true);
      background: $color-main-grey;
    }
    body:not(.no-header-visual) & {
      @include coloured-label($link: true, $uppercase: true);
    }
    line-height: 54px;
  }
}