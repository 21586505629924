// hack to display table borders
.content-table, .content-table thead, .content-table tbody, .content-table th, .content-table td, .content-table tr {
  -webkit-backface-visibility: visible;
}

.content-table {
  @include content-section-block;
  @include max-width;
  margin-bottom: $margin-small;

  .table {
    overflow-x: scroll;
  }

  h2 {
    @include typography-header-4;
    width: 100%;
    line-height: 60px;
    background-color: $color-main-green;
    padding: 0 15px;
  }

  table {
    text-align: left;

    tr:nth-child(even) {
      background-color: $color-lightest-grey;
    }

    th, td {
      padding: 5px 15px;
    }

    th:not(:last-child), td:not(:last-child) {
      border-right: 1px solid $color-main-grey;
    }

    th {
      @include typography-default-bold;
      border-bottom: 1px solid $color-tertiary-grey;
    }

    td {
      @include typography-default;
      border-bottom: 1px solid $color-main-grey;
    }
  }
}