@mixin video-play-button() {

  &:after {
    box-sizing: border-box;
    position: absolute;
    display: block;
    font: normal 30px/60px icons;
    padding-left: 4px;
    // fix font placement
    content: $icon-play;
    text-align: center;
    font-size: 55px;
    color: $color-main-white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
  }
}

//.video-link {
//  position: relative;
//  cursor: pointer;
//  @extend %video-play-button;
//}
//.contest-video .video{
//  position: relative;
//  @extend %video-play-button;
//  &:after {
//    margin-top: -50px;
//  }
//}