

.page-results-section .result {
  @include hover {
    background-color: $color-main-green !important;
  }
}

.searchresults {
  $min-logo-width: 100px;

  .inner-margined {
    min-height: 600px;

    @include below($breakpoint-mobile) {
      min-height: 200px;
    }
  }

  // Custom main header
  .page-sections {
    background-color: $color-main-blue;
    left: 0;
    margin-top: 0;
    padding-top: $logosize / 2;
    top: 0;

    .inner-margined {
      margin-top: 0;
    }
  }

  // Search form
  .page-search-form {
    font-size: 0;
    @include grid-padding();

    .page-title {
      padding-left: 0;
      margin-bottom: 10px;
    }
  
    form {
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
      margin-bottom: 30px;
      max-width: 620px;
  
      .search-input {
        width: calc(100% - 50px);
        > input {
          width: 100%;
          height: 50px;
          padding-left: 20px;
          vertical-align: bottom;
          border: 2px solid $color-main-grey;
          border-right: none;
          background-color: $color-main-white;
          @include typography-default();
        }
      }
  
      button {
        @include base-icon($icon-data-search, $button-size, $bg-color: $color-main-green, $fg-color: $color-main-black, $location: 'before');
        vertical-align: bottom;
        cursor: pointer;
      }

      button[disabled='disabled'] {
        cursor: default;
      }
    }
  }

  // Page results
  .page-results-section {
    @include max-width;
    margin-bottom: 25px;
  }

  .no-results,
  .result-count {
    @include grid-padding();
    color: $color-secondary-grey;
    @include typography-default;
    //line-height: 44px;
    display: block;
    padding-bottom: 30px;
  }

  .result {
    min-height: 180px;


    &:nth-of-type(odd) {
      background-color: $color-lightest-grey;
    }

    a {
      display: block;
      padding: 25px 80px;
      @include grid-padding();
    }

    > a > h2 {
      @include typography-header-4;
      margin-bottom: 10px;
    }

    > a > p {
      @include typography-default;
      margin-bottom: 10px;
    }

    > a > span {
      color: $color-secondary-grey;
      @include typography-default
    }
  }

  // search navigation
  .pagination {
    @include grid-padding();
    @include max-width;
    text-align: center;
    margin-bottom: 80px;

    .button-previous,
    .button-next {
      height: 50px;
      float: left;
      display: inline-block;
      line-height: 50px !important;
      background-color: $color-main-grey;
      @include typography-default-bold;
    }
    span.button-disabled {
      opacity: 0.5;
      cursor: default;
      &:hover {
        background-color: $color-main-grey;
        color: $color-main-black;
      }
    }

    .button-previous {
      @include base-icon($icon-data-triangle-right,$button-size,$bg-color:$color-main-grey,$fg-color:$color-main-black,$location:'before');
      padding-right: 25px;

      &::before {
        @include transform(rotate(180deg));
      }
    }
    a.button-previous {
      &:hover {
        color: $color-main-white;
        background-color: $color-main-black;

        &::before {
          color: $color-main-white;
          background-color: $color-main-black;
        }
      }
    }

    ul {
      //display: inline-block;
      display: none; // altijd uit, nutteloos eigenlijk
      @include below($breakpoint-mobile) {
         display: none;
      }
    }

    li {
      width: 50px;
      height: 50px;
      margin: 0 5px 5px 0;
      display: inline-block;
      background-color: $color-lightest-grey;

      a, span {
        width: 50px;
        height: 50px;
        display: inline-block;
        line-height: 50px !important;
        @include typography-default-bold;
      }

      a {
        &:hover {
          color: $color-main-white;
          background-color: $color-main-black;
        }
      }

      span {
        background-color: $color-main-green;
      }
    }

    .button-next {
      @include base-icon($icon-data-triangle-right,$button-size,$bg-color:$color-main-grey,$fg-color:$color-main-black,$location:'after');
      float: right;
      padding-left: 25px;
    }
    a.button-next {
      &:hover {
        color: $color-main-white;
        background-color: $color-main-black;

        &::after {
          color: $color-main-white;
          background-color: $color-main-black;
        }
      }
    }
    .top-container {
      height: 50px;
      margin-bottom: 25px;
      display: block;

    }
  }
  
  @include above($breakpoint-maxlogowidth) {
    .page-sections {
      left: 0;
      padding-top: $logomaxwidth / 2;
    }
  }

  @include below($breakpoint-mobile) {
    .page-sections {
      padding-top: $min-logo-width;

      .page-left-spacer {
        width: 20px;
      }

      .inner-margined {
        max-width: calc(100% - 20px);
      }
    }

    .result {
      min-height: 0;

      > a > p {
        display: none;
      }
    }
  }

  @include below($breakpoint-portrait + 100) {
    .search-input {

        > input {
          width: auto;
        }
      }
  }
}