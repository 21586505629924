
@function bezier_axis($x1, $x2, $t) {
  $cx: 3.0 * $x1;
  $bx: 3.0 * ($x2 - $x1) - $cx;
  $ax: 1.0 - $cx - $bx;
  @return $t * ($cx + ($t * ($bx + ($t * $ax))));
}

@function bezier_derivate($x1, $x2, $x) {
  $cx: 3.0 * $x1;
  $bx: 3.0 * ($x2 - $x1) - $cx;
  $ax: 1.0 - $cx - $bx;
  @return ((3.0 * $ax * $x + 2.0 * $bx) * $x) + $cx;
}

@function cubic-bezier($x1, $y1, $x2, $y2, $percentage) {
  $x: $percentage;
  $i: 0;
  @while ($i < 10) {
    $z: bezier_axis($x1, $x2, $x) - $percentage;
    $absz: abs($z);
    @if $absz < 0.000001 {
    // poormans break
      $i: 10;
    } @else {
      $x: $x - ($z / bezier_derivate($x1, $x2, $x));
      $i: $i + 1;
    }
  }
  @return bezier_axis($y1, $y2, $x);
}
