// Fix for Bourbon Transition Mixin Bug
@mixin trans-prefix($value, $prefix: '') {
  $slice: str-slice(inspect($value), 0, 9);

  @if $slice == 'transform' {
    #{$prefix}transition: #{$prefix}#{$value};
  } @else {
    #{$prefix}transition: $value;
  }
}

// Fix for Bourbon Transition Mixin Bug
@mixin transition($properties...) {
  $spec: $properties;

  @if length($properties) > 1 {
    $spec: ();

    @for $i from 1 through length($properties) {
      $spec: append($spec, nth($properties, $i), comma);
    }
  }

  @include trans-prefix($spec, -webkit-);
  @include trans-prefix($spec, -moz-);
  @include trans-prefix($spec);
}
