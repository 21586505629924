
.page-header {
  @include themed-main;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: $header-with-image-size;
  z-index: 2;
  .page-header-image {
    height: $header-with-image-size;
    width: 100vw;
    position: relative;
    min-height: $header-with-image-size + 30px;

    @media screen and (max-width: 529px) {
        min-height: 290px;
    }

    .page-header-image__tag {
      display: block;
      object-fit: cover;
      object-position: 42% 36%;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  body.simple-overview-page & {
    height: $header-with-image-size-simple;
    min-height: $header-with-image-size-simple;
  }

  @include below($breakpoint-mobile) {
    .page-header-image {
      height: $header-with-image-size-mobile;
    }

    body.simple-overview-page & {
      height: $header-with-image-size-simple-mobile;
      min-height: $header-with-image-size-simple-mobile;
    }
  }
}

@mixin page-overview-header {
  body & {
    text-align: left;
    position: absolute;
    left: 0;
    @include transform(translateY(-100%));
    z-index: $layer-content;
    width: 100%;
    padding: 20px 20px 10px 21.1%;

    @include above($breakpoint-maxlogowidth) {
      padding-left: $logomaxwidth + 20px;
    }
  }

  body.simple-overview-page.has-visual & {
    @include transform(translateY(calc(-1 * 100% - 35px)));
    @include below($breakpoint-portrait) {
      text-align: center;
    }
    @include below(666px) {
      @include transform(translateY(calc(-1 * 100% - 20px)));
    }
    padding: 20px;
    position: relative;
    background-color: $color-main-white;
    height: 120px;
    margin-bottom: -120px;
    @include below($breakpoint-mobile) {
      margin-bottom: -88px;
    }
    @include above($breakpoint-mobile) {
      &:after {
        content: "";
        height: 100%;
        width: 51%;
        background: $color-main-white;
        position: absolute;
        right: -50%;
        top: 0;
      }
    }

  }

  @include below($breakpoint-mobile) {
    body {
      @include themed-main();
      position: relative;
      @include transform(translateY(0%));
      padding: 20px;
    }

    body.simple-overview-page:not(.searchresults):not(.theme-overview-page) & {
      // Titles are placed below the logo, therefore the padding is adjusted
      padding: 10px 20px;
    }

    body.simple-overview-page.has-visual & {
      height: 88px;
    }
  }
}
