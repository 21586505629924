@mixin content-section-block($mode:"padding") {
  @include grid-padding($mode:$mode);
  background-color: $color-main-white;
  position: relative;
  z-index: $layer-content;
}

@mixin add-section-border($location:'before') {
  position: relative;

  &::#{$location} {
    display: block;
    position: absolute;
    width: 2px;
    height: 84px;
    background-color: $color-secondary-black;
    content: '';
    top: 0;
    left: 0;
  }
}
