@mixin play-icon-small {
  @include add-icon($icon-play) {
    color: $color-main-white;
    font-size: 50px;
    position: absolute;
    left: calc(50% - 23px);
    top: calc(50% - 65px);
  }

  &:hover, &.on-hover {
    &::after {
      color: $color-main-green;
    }
  }
}

@mixin play-icon-big {
  @include add-icon($icon-play) {
    color: $color-main-white;
    font-size: 80px;
    position: absolute;
    left: calc(50% - 36px);
    top: calc(50% - 40px);
  }

  &:hover, &.on-hover {
    &::after {
      color: $color-main-green;
    }
  }
}

.content-video {
  .video-link {
    @include play-icon-big;
    cursor: pointer;
    position: relative;
    
      &:focus {
        outline: 2px solid #005fcc;
      }
    
    
  }
}

.video-container {
  @include grid-padding;

  .video {
    @include play-icon-small;
    width: 290px;
    height: 255px;
    padding-bottom: $margin-small;
    font-size: 0;

    &:hover {
      text-decoration: none !important;
    }

    figcaption {
      background-color: $color-main-black;
      @include typography-paragraph;
      width: 290px;
      height: 65px;
      position: relative;
      color: $color-main-white;
      text-decoration: none;
      padding: 10px;
      overflow: hidden;
    }
  }
}