.home-banner {
  background: #000;
  display: block;
  font-size: 20px;
  font-family: $font-campton-medium;
  height: 60px;
  width: 100vw;
  padding: 20px 40px;
  position: absolute;
  text-decoration: none;
  top: calc(100vh - 60px);
  z-index: 12;

  @include below(900px) {
    padding: 20px 20px;
  }

  @include above(1920px) {
    font-size: 32px;
    height: 72px;
    top: calc(100vh - 72px);
  }

  span {
    color: $color-main-green;

    &.w {
      color: #fff;
      display: inline-block;
      margin-left: 30px;
      background: #000;
    }

    @include below(530px) {
      display: block;
      margin-left: 0;
      padding: 10px 20px;
      background: $color-main-green;
      color: #000;

      &.w {
        margin-left: 0;
        width: 100vw;
      }
    }
  }

  @include below(530px) {
    height: 80px;
    position: fixed;
    bottom: 0;
    top: auto;
    padding: 0;
  }
}
