// The Homepage body element
.home-page {
  // Colored block on one of the four corners of a homepage slide
  @mixin colored-block($color) {
    position: relative;

    &::before {
      background-color: $color;
      content: '';
      display: block;
      padding-bottom: $block-size;
      position: absolute;
      width: $block-size;
      z-index: 3;
      @content;
    }

    @include below($breakpoint-mobile) {
      &::before {
        padding-bottom: $block-size-mobile;
        width: $block-size-mobile;
      }
    }
  }

  // Position container for text block
  .wrapper {
    position: relative;
    z-index: 5;

    h1, h2 {
      @include typography-header-2;
      margin-bottom: 10px;
    }

    p {
      @include typography-intro;
    }

    .button-main {
      @include text-icon-button;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    // Background container for text block
    .slide-title {
      background-color: $color-main-white;
      padding: 35px 40px 90px;
      position: relative;
    }
  }

  // A home page slide
  .slide-content {
    &.v-video {
    }

    background-size: cover;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;

    // Background of a home page slide
    .background {
      height: 100%;
      position: relative;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .e-extra-logo-group {
      bottom: 30px;
      padding-left: 40px;
      padding-right: 40px;
      position: absolute;
      width: 100%;
      z-index: 4;
    }

    .e-sponsor-logos {
      text-align: right;
    }

    .e-bgl-logo,
    .e-vl2021-logo,
    .e-basf-logo,
    .e-mod-logo,
    .e-google-logo {
      margin-bottom: 30px;
      margin-left: 30px;
      height: 20px;
      float: right;

      img {
        height: 100%;
        max-height: 100%;
        width: auto;
      }
    }

    .e-vl2021-logo {
      height: 12px;
      margin-top: 4px;
    }

    .e-mod-logo {
      transform: scale(3.5);
      left: -43px;
      position: relative;
      top: -18px;
      margin-left: 140px;

      @include below($breakpoint-mobile) {
        transform: scale(2.5);
        margin-left: 82px;
        top: -16px;
        left: -32px;
      }
    }

    .e-basf-logo {
      height: 70px;
      margin-bottom: 0;
      position: relative;
      top: -15px;
    }

    .e-basf-logo {
      padding-bottom: 3px; // To adjust size to the capital letters of the other logos
    }

    .e-sm-logo {
      clear: right;
      float: left;
      height: auto;
      max-height: 200px;
      max-width: 100%;
    }

    // First child is the main slide and logo slide
    &:nth-of-type(1) {
      .wrapper {
        @include text-block;
        left: $logominsize;
        padding-top: $logominsize;
        top: 0;

        @include above($breakpoint-mobile) {
          left: $logosize;
          padding-top: $logosize / 2;
        }

        @include above($breakpoint-maxlogowidth) {
          left: $logomaxwidth;
          padding-top: $logomaxwidth / 2;
        }
      }
    }

    // Top left slide variant
    &.v-topleft {
      @include colored-block($color-main-black) {
        left: 0;
        top: 0;
      }

      .wrapper {
        @include text-block;
        left: $block-size;
        top: 0;
      }
    }

    // Top right slide variant
    &.v-topright {
      @include colored-block($color-main-black) {
        right: 0;
        top: 0;
      }

      .wrapper {
        @include text-block;
        right: $block-size;
        top: 0;
      }
    }

    // Bottom left slide variant
    &.v-bottomleft {
      @include colored-block($color-main-black) {
        bottom: 0;
        left: 0;
      }

      .wrapper {
        @include text-block;
        bottom: 0;
        padding-bottom: $block-size;
        left: $block-size;
      }
    }

    // Bottom right slide variant
    &.v-bottomright {
      @include colored-block($color-main-black) {
        bottom: 0;
        right: 0;
      }

      .wrapper {
        @include text-block;
        bottom: 0;
        padding-bottom: $block-size;
        right: $block-size;
      }
    }

    // Blue theme slide variant
    &.v-blue {
      &::before {
        background-color: $color-main-blue;
      }
    }

    // Green theme slide variant
    &.v-green {
      &::before {
        background-color: $color-main-green;
      }
    }

    // Pink theme slide variant
    &.v-pink {
      &::before {
        background-color: $color-main-pink;
      }
    }

    // Red theme slide variant
    &.v-red {
      &::before {
        background-color: $color-main-red;
      }
    }

    // Yellow theme slide variant
    &.v-yellow {
      &::before {
        background-color: $color-main-yellow;
      }
    }
  }

  // Pagination element of the home page
  .home-pagination {
    position: fixed;
    right: 20px;
    top: calc(50% - 32px);
    z-index: $layer-navigation;

    @include below($breakpoint-mobile) {
      top: calc(60%);
    }

    // Single pagination item
    li {
      list-style-type: none;
      display: block;
      padding: 0;
      background-color: $color-main-white;
      height: 8px;
      margin-bottom: 8px;
      width: 8px;

      &.active {
        background-color: $color-main-green;
      }
    }
  }

  @include below($breakpoint-maxlogowidth) {
    .wrapper {
      h1, h2 {
        @include typography-header-3;
      }

      p {
        @include typography-default;
      }

      .slide-title {
        padding: 30px 30px 90px;
      }
    }
  }

  @include mobile-landscape {
    .wrapper {
      .slide-title {
        padding-bottom: 65px;

        h1,
        h2 {
          font-size: 30px;
          line-height: 1.1;
        }
      }
    }

    .slide-content {
      .e-bgl-logo,
      .e-basf-logo,
      .e-google-logo {
        //margin-left: 30px;
      }

      .e-bgl-logo,
      .e-vl2021-logo,
      .e-google-logo {
        height: 20px;
        margin-bottom: 0;
      }

      .e-basf-logo {
        height: 48px;
        margin-bottom: -10px;
        padding-bottom: 0;
        position: relative;
        top: -10px;
      }
    }
  }

  @include below($breakpoint-mobile) {
    .wrapper {
      .slide-title {
        padding: $side-padding-mobile $side-padding-mobile 70px;
      }
    }

    .slide-content {
      background-size: cover;
      height: 100vh;

      &.v-topleft {
        &::before {
          bottom: 0;
          top: auto;
        }

        .wrapper {
          bottom: 0;
          padding-bottom: $block-size-mobile;
          left: $block-size-mobile;
          top: auto;
        }
      }

      &.v-topright {
        &::before {
          bottom: 0;
          top: auto;
        }

        .wrapper {
          bottom: 0;
          padding-bottom: $block-size-mobile;
          right: $block-size-mobile;
          top: auto;
        }
      }

      &.v-bottomleft {
        .wrapper {
          bottom: 0;
          padding-bottom: $block-size-mobile;
          left: $block-size-mobile;
        }
      }

      &.v-bottomright {
        .wrapper {
          bottom: 0;
          padding-bottom: $block-size-mobile;
          right: $block-size-mobile;
        }
      }

      &:nth-child(1) {
        .wrapper {
          left: $block-size-mobile;
          padding-top: $block-size-mobile / 2;
        }
      }

      .e-extra-logo-group {
        padding-left: 20px;
        padding-right: 20px;
        bottom: 20px;
      }

      .e-sponsor-logos {
        // A bit more close to the nemosciencemuseum logo
        position: relative;
        top: 10px;

        @include below($breakpoint-mobile) {
          top: 26px;
        }
      }

      .e-bgl-logo,
      .e-vl2021-logo,
      .e-basf-logo,
      .e-google-logo {
        margin-bottom: 10px;
        margin-left: 10px;
        height: 18px;

        @include below($breakpoint-mobile) {
          height: 12px;
        }
      }

      .e-basf-logo {
        height: 50px;
        margin-bottom: 0;
        top: -10px;
        width: auto;
      }
    }
  }

  &--has-banner {
    .slide-content {
      .e-extra-logo-group {
        bottom: 80px;
      }
    }
  }
}
