@mixin label(
  $height: 50px,
  $line-height: 50px,
  $padding-horizontal: 25px,
  $fg-color: $color-main-black,
  $link: false,
  $uppercase: false,
  $mode: "grid") {
  $height-px: $height;

  @include typography-label;

  color: $fg-color;
  display: inline-block;
  height: $height-px;
  line-height: $height-px;
  padding-left: $padding-horizontal;
  padding-right: $padding-horizontal;

  @if $link {
    @include hover {
      text-decoration: none;
      background-color: $color-main-black;
      color: $color-main-white;
    }
  }

  @if $uppercase {
    text-transform: uppercase;
  }

  @content;
}

// Label with background color, and mode with hover color.
@mixin coloured-label(
  $height: 50px,
  $line-height: 50px,
  $padding-horizontal: 25px,
  $fg-color: $color-main-black,
  $link: false,
  $uppercase: false,
  $mode: "grid") {
  @include label($height, $line-height, $padding-horizontal, $fg-color, $link, $uppercase, $mode);
  @include themed-support;
}

@mixin small-black-label {
  @include typography-label;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 30px;
  line-height: 30px;
  background-color: $color-main-black;
  color: $color-main-white;
  padding: 0 15px;
  z-index: 1;
}

@mixin ribbon-label {
  @include typography-label;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 30px;
  line-height: 30px;
  background-color: $color-main-green;
  color: $color-main-black;
  padding: 0 15px;
  z-index: 1;
}

.c-coloured-label {
  @include coloured-label;
}

// figcaption
@mixin figcaption-label {
  @include typography-label;
  background-color: $color-main-black;
  color: $color-main-white;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

@mixin coloured-label-small {
  @include typography-label;
  font-size: 16px; 
  text-transform: uppercase;
  min-height: 32px;
  line-height: 35px;
  display: inline-block;
  @include themed-support;
  color: $color-main-black;
  padding: 0 15px;
  z-index: 1;
}

.ribbon {
  @include ribbon-label;

  span {
    font-size: 16px;
  }
}

@mixin ribbon-category-label {
  @include typography-label;
  font-size: 14px;
  letter-spacing: 0.5px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: block;
  height: 30px;
  line-height: 30px;
  background-color: $color-main-black;
  color: $color-main-white;
  z-index: 1;
  padding: 0 22px;

  @include below($breakpoint-mobile-overview-blocks) {
    padding: 0 10px;
    width: 100%;
    overflow: hidden;
  }
}

.ribbon-category {
  @include ribbon-category-label;
}

.page-representation__header {
  .ribbon-category {
   font-weight: 300;
  }
}

@mixin ribbon-mobile-label {
  @include typography-label;
  font-size: 12px;
  text-transform: uppercase;
  height: 20px;
  line-height: 20px;
  background-color: $color-main-green;
  color: $color-main-black;
  padding: 0 10px;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden;
}

.ribbon-mobile {
  @include ribbon-mobile-label;
}
