

.lightbox-cover {
  background: rgba($color-main-black, 0.65);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: $layer-lightbox + 1;
}

.lightbox-page-wrapper {
  display: none;
  height: 100%;
  min-height: 400px;
  overflow: auto;
  position: fixed;
  padding-top: 180px;
  top: 0 !important;
  width: 100%;
  z-index: $layer-lightbox + 1;

  body > & {
    display: block;
  }

  @include below($breakpoint-mobile) {
    background-color: $color-main-white;
    padding-top: 0;
  }
}

.lightbox-page-container {
  $control-space: $button-size;
  $content-width: calc(100% - #{$control-space});
  background: transparent;
  height: auto;
  position: relative;
  margin: 0 auto;
  max-width: calc(100% - #{80px - $control-space});
  padding-top: $control-space;
  padding-bottom: 180px;
  width: 720px + $control-space;

  > h2 {
    @include typography-header-3;
    background: $color-main-white;
    padding: 30px 50px 20px;
    width: $content-width;
  }

  > h3 {
    @include typography-header-4;
    background: $color-main-white;
    padding: 22px 50px 15px;
    width: $content-width;
  }

  p.subtitle {
    display: none;  // seems to not be used anymore
  }

  > p {
    @include typography-default;
    @include rich-text;
    background: $color-main-white;
    padding: 35px 50px 45px;
    width: $content-width;
  }

  p + p {
    padding-top: 0;
    position: relative;
    margin-top: -20px;
  }

  .lightbox-outset {
    font-size: 0;
    width: $content-width;

    iframe, .lightbox__iframe {
      min-height: 300px;
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .lightbox-video {
    font-size: 0;
    width: $content-width;
    padding-bottom: 62%;
    position: relative;

    &:focus {
      border: 10px solid cyan;
    }

    .video-link {
      cursor: pointer;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      @include add-icon($icon-play) {
        color: $color-main-white;
        font-size: 80px;
        position: absolute;
        left: calc(50% - 36px);
        top: calc(50% - 40px);
      }

      img {
        height: 100%;
        width: 100%;
      }

      &:hover, &.on-hover {
        &::after {
          color: $color-main-green;
        }
      }
    }

    iframe, .lightbox__iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .video-link + iframe, .video-link + .lightbox__iframe {
      display: none;
      z-index: 5;
    }
  }

  .intro {
    @include typography-intro;
    background: $color-main-white;
    padding: 35px 50px 45px;
    width: $content-width;
  }

  .share {
    @include clearfix;
    background-color: $color-main-white;
    padding: 20px 50px 40px;
    width: $content-width;

    p {
      float: left;
      @include typography-header-4($line-height:40px 40px);
      padding: 0;
      width: auto;
    }

    .page-share-wrapper {
      float: right;
      font-size: 0;

      li {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 1px;
        }
      }
    }
  }

  .intro + .share {
    position: relative;
    margin-top: -20px;
  }

  p + .share {
    position: relative;
    margin-top: -20px;
  }

  .collection-pieces, .exhibits {
    background-color: $color-main-grey;
    margin: 0;
    padding: 30px 50px;
    width: $content-width;
  }

  @include below($breakpoint-mobile) {
    $content-width: 100%;
    $padding-horizontal: 20px;
    max-width: $content-width;
    padding-bottom: 0;
    width: $content-width;

    h2 {
      padding: 20px $padding-horizontal 20px;
      width: $content-width;
    }

    h3 {
      padding: 15px $padding-horizontal 10px;
      width: $content-width;
    }

    p.subtitle {
      display: none;  // seems to not be used anymore
    }

    p {
      padding: 15px $padding-horizontal 25px;
      width: $content-width;
    }

    p + p {
      padding-top: 0;
      position: relative;
      margin-top: -10px;
    }

    .lightbox-outset {
      width: $content-width;

      iframe, .lightbox__iframe {
        min-height: 250px;
      }
    }

    .lightbox-video {
      width: $content-width;
    }

    .share {
      padding: 10px $padding-horizontal 20px;
      width: $content-width;

      p {
        float: none;
      }

      .page-share-wrapper {
        float: none;
      }
    }

    .intro + .share {
      margin-top: -10px;
    }

    p + .share {
      margin-top: -10px;
    }

    .collection-pieces, .exhibits {
      padding: 20px $padding-horizontal;
      width: $content-width;
    }
  }
}

.lightbox-close {
  $size: $button-size;
  @include base-icon($icon-data-close, $size, transparent);
  background-color: $color-main-green;
  color: $color-main-black;
  display: inline-block;
  font-size: 0;
  height: $button-size;
  position: absolute;
  right: 0;
  top: 0;
  width: $button-size;
  vertical-align: bottom;

  &:hover, &.on-hover,
  &:active, &.on-click {
    @include icon-color-variation($fg-color: $color-main-white);
    background-color: $color-main-black;
    color: $color-main-white;
    cursor: pointer;
  }

  @include below($breakpoint-mobile) {
  }
}

.page-section .content-video {
  position: relative;

  .video-link {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;   
  }

}
