@keyframes hide {
  0% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.0;
    transform: translateY(100%);
  }
}

.newsletter-popup {
  z-index: 2000;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease, transform 0.4s ease;
  width: 100%;

  &:not([data-state="active"]):not([data-state="deactivating-active"]) {
    .newsletter-popup__state-active {
      display: none;
    }
  }
  &:not([data-state="error"]):not([data-state="deactivating-error"]) {
    .newsletter-popup__state-error {
      display: none;
    }
  }
  &:not([data-state="success"]):not([data-state="deactivating-success"]) {
    .newsletter-popup__state-success {
      display: none;
    }
  }

  &:not(.newsletter-popup--activated) {
    transform: translateY(100%);
  }

  &[data-state="deactivating-active"],
  &[data-state="deactivating-error"],
  &[data-state="deactivating-success"] {
    animation: hide 0.4s ease;
  }

  @include above($breakpoint-medium) {
    max-width: 560px;
  }
}

.newsletter-popup__state-active {
  background: $color-main-green;
  padding-bottom: 1px;
}

.newsletter-popup__state-error {
  background: $color-main-red;
  padding-bottom: 1px;
}

.newsletter-popup__state-success {
  background: $color-main-yellow;
  padding-bottom: 1px;
}

.newsletter-popup__active-content {
  margin-bottom: 20px;
  position: relative;

  @include above($breakpoint-medium) {
    margin-bottom: 50px;
  }
}

.newsletter-popup__error-content {
  margin-bottom: 20px;
  position: relative;

  @include above($breakpoint-medium) {
    margin-bottom: 50px;
  }
}

.newsletter-popup__success-content {
  margin-bottom: 20px;
  position: relative;

  @include above($breakpoint-medium) {
    margin-bottom: 50px;
  }
}

.newsletter-popup__button-row {
  display: flex;
  justify-content: flex-end;
}

.newsletter-popup__title {
  font: 500 18px / 25px $font-campton-medium;
  margin: 0 70px 10px 20px;

  @include above($breakpoint-medium) {
    font: 500 26px / 30px $font-campton-book;
    margin: 20px 30px 10px 30px;
  }
}

.newsletter-popup__information {
  font: 500 14px / 22px $font-campton-book;
  margin: 0 50px 10px 20px;

  @include above($breakpoint-medium) {
    font: 500 16px / 24px $font-campton-book;
    margin: 0 30px 10px 30px;
  }
}

.newsletter-popup__disclaimer {
  font: 500 12px / 13px $font-campton-book;
  margin: 0 50px 10px 20px;

  a {
    text-decoration: underline;
  }

  p {
    font-size: 16px;
  }

  @include above($breakpoint-medium) {
    bottom: 0;
    position: absolute;
    margin: 20px 30px 20px 30px;
    right: 0;
  }
}

.newsletter-popup__email {
  margin: 20px 50px 30px 20px;

  label {
    display: none;
  }

  input {
    @include input-field(false);
    outline: none;
  }

  @include above($breakpoint-medium) {
    margin: 20px 30px 30px 30px;
  }
}

.newsletter-popup__cta-row {
  margin: 30px 50px 20px 20px;

  @include above($breakpoint-medium) {
    margin: 30px 30px 30px 30px;
  }

  .c-text-icon-button  {
    padding-right: 28px;
    border: 0;
  }
}
