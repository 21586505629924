$breakpoint-extrasmall: 370px;
$breakpoint-mobile: 530px;
$breakpoint-mobile-overview-blocks: 666px;
$breakpoint-small: 620px + 40px; // 660px -- 2 * 300 + 3 * 20
$breakpoint-portrait: 900px;
$breakpoint-medium: 940px + 40px; // 980px -- 3 * 300 + 4 * 20
$breakpoint-ipad-landscape: 1024px;
$breakpoint-landscape: 1200px;
$breakpoint-large: 1260px + 40px; // 1300px -- 4 * 300 + 5 * 20
$breakpoint-full-logo: 1570px;

@mixin above($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin below($width) {
  @media screen and (max-width: $width - 1) {
    @content;
  }
}

@mixin between($width1, $width2) {
  @media screen and (min-width: $width1) and (max-width: $width2 - 1) {
    @content;
  }
}
